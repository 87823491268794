import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Box, FormControl, FormHelperText, FormLabel, Typography } from '@mui/material';
import { useField } from 'formik';
import { ChangeEvent, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { InfoDialog } from '../../components/investors/components';
import { SxProps } from '@mui/system';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  paddingRight: '10px',
  borderRadius: '12px',
  backgroundColor: 'rgba(240, 242, 245, 0.8)',
  border: '1px solid transparent',
  color: 'rgba(38, 48, 60, 0.6)',
  marginLeft: 0,
  marginBottom: '10px',
  '&': checked && {
    backgroundColor: 'rgba(0, 87, 155, 0.0535)',
    border: '1px solid #00579B',
  },
  '.MuiFormControlLabel-label': {
    fontWeight: 500,
    fontSize: '14px',
    '&': checked && {
      color: theme.palette.primary.main,
    },
  },
}));

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

export default function UseRadioGroup({
  name,
  formLabel,
  defaultValue,
  items,
  labelColor = 'rgba(38, 48, 60, 0.7)',
  value = '',
  disabled = false,
  onChange,
}: {
  name: string;
  formLabel?: string;
  defaultValue?: string | boolean;
  labelColor?: string;
  items: { label: string; value: string | boolean | number }[];
  value?: boolean | string | undefined | number;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const fieldValue = value ? value : field.value;
  onChange = onChange || field.onChange;

  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      {formLabel && (
        <FormLabel
          component="legend"
          sx={{ mb: 2, fontWeight: 500, fontSize: 14, color: labelColor }}>
          {formLabel}
        </FormLabel>
      )}
      <RadioGroup
        row
        defaultValue={defaultValue}
        {...field}
        onChange={onChange}
        value={fieldValue}
        sx={{
          '& .Mui-disabled': {
            cursor: !disabled ? 'pointer' : 'not-allowed !important',
            pointerEvents: 'all',
          },
        }}>
        {items.map((item, index) => (
          <MyFormControlLabel
            key={index}
            label={item.label}
            value={item.value}
            disabled={disabled}
            control={<Radio disableRipple size="small" sx={{ ':hover': { background: 'none' } }} />}
          />
        ))}
      </RadioGroup>
      {errorText && (
        <FormHelperText error sx={{ marginLeft: 'unset' }}>
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

const StyledFormControlLabelr = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  paddingRight: '10px',
  borderRadius: '12px',
  marginLeft: 0,
  marginBottom: '10px',
  '.MuiFormControlLabel-label': {
    fontWeight: 500,
    fontSize: '14px',
    '&': checked && {
      color: theme.palette.primary.main,
    },
  },
}));

export function MyFormControlLabels(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabelr checked={checked} {...props} />;
}

export function UseRadioGroupTransparent({
  name,
  defaultValue,
  items,
  value = '',
  disabled = false,
  formLabel,
  onChange,
  sx,
  TableRender,
  relatedPartyLimit,
  row = true,
}: {
  name: string;
  defaultValue?: string | boolean;
  items: { label: any; value: string | boolean | number; info?: string }[];
  value?: boolean | string | undefined | number;
  disabled?: boolean;
  formLabel?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
  TableRender?: any;
  relatedPartyLimit?: string;
  row?: boolean;
}): JSX.Element {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const [dialogMessage, setDialogMessage] = useState({ message: '', title: '' });
  const [open, setOpen] = useState(false);
  const handleOpen = ({ message, title }: { message: string; title: string }) => {
    setOpen(true), setDialogMessage({ message, title });
  };
  const handleClose = () => {
    setOpen(false);
    setDialogMessage({ message: '', title: '' });
  };
  const fieldValue = value ? value : field.value;
  onChange = onChange || field.onChange;

  return (
    <FormControl component="fieldset" sx={{ width: '100%', ...sx }}>
      {formLabel && (
        <FormLabel component="legend" sx={{ mb: 2, fontWeight: 500, fontSize: 14 }}>
          <Typography sx={{ fontWeight: 600, fontSize: 14, color: '#131836' }}>
            {formLabel}
          </Typography>
        </FormLabel>
      )}
      <RadioGroup
        row={row}
        defaultValue={defaultValue}
        {...field}
        onChange={onChange}
        value={fieldValue}
        sx={{
          '& .Mui-disabled': {
            cursor: !disabled ? 'pointer' : 'not-allowed !important',
            pointerEvents: 'all',
          },
        }}>
        {items.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', flexDirection: 'row' }}>
            <MyFormControlLabels
              label={item.label}
              value={item.value}
              disabled={disabled}
              control={
                <Radio disableRipple size="small" sx={{ ':hover': { background: 'none' } }} />
              }
            />
            {item?.info && (
              <Typography component="span" sx={{ position: 'relative', left: -25, bottom: -10 }}>
                <InfoOutlinedIcon
                  color="info"
                  fontSize="small"
                  onClick={() => handleOpen({ message: item?.info || '', title: item.label })}
                />
              </Typography>
            )}
            {index === 0 && relatedPartyLimit === 'consent' && (
              <Box sx={{ mt: 4, mb: { xs: 4, sm: 0 } }}>{TableRender} </Box>
            )}
          </Box>
        ))}
        <InfoDialog
          onClose={handleClose}
          open={open}
          content={dialogMessage.message}
          title={dialogMessage.title}
          infoIcon={false}
        />
      </RadioGroup>
      {errorText && (
        <FormHelperText error sx={{ marginLeft: 'unset' }}>
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
