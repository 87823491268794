import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import data from './data-ts';
import { Chart as ChartJS, ChartData, ChartOptions, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { numToAlpMonth } from '../../utils/utilityFunctions';
import { monthwiseOnboardingSummaryType } from '../../redux-store/types/api-types';
import { monthwiseOnboardingSummary } from '../../redux-store/actions/application';
import { Card, Grid, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { RootStateType } from '../../redux-store/reducers';

ChartJS.register(...registerables);

export const monthWise: monthwiseOnboardingSummaryType = {
  data: [
    {
      month: '',
      individual: 0,
      nonIndividual: 0,
      poa: 0,
      nri: 0,
    },
  ],
  year: '',
};

function Status({
  backgroundColor,
  text,
  count,
}: {
  backgroundColor: string;
  text: string;
  count: number;
}): JSX.Element {
  return (
    <Box
      sx={{
        backgroundColor: { backgroundColor },
        display: 'flex',
        borderRadius: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1,
        height: 60,
        color: 'white',
      }}>
      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>{text}</Typography>
      <Typography sx={{ fontSize: 29, fontWeight: 600 }}>{count}</Typography>
    </Box>
  );
}

export default function MonthOnMonthAndOnboardingStatus(): JSX.Element {
  const dispatch = useDispatch();
  const [monthOnMonth, SetMonthOnMonth] = useState(monthWise);
  const MonthsData = useSelector((store: RootStateType) => store.monthOnMonthData);

  const totalStatus = MonthsData.data.filter((data) => {
    return data.month === 'total';
  });

  useEffect(() => {
    let componentIsAlive = true;
    (async function () {
      try {
        (await dispatch(monthwiseOnboardingSummary())) as unknown as monthwiseOnboardingSummaryType;
        if (!componentIsAlive) {
          return;
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, []);

  useEffect(() => {
    SetMonthOnMonth(MonthsData);
  }, [MonthsData]);

  //month on month bar Graph data Initialization start

  const month = [] as any;

  const monthArr = MonthsData.data
    .filter((data) => {
      return data.month != 'total';
    })
    .map((data) => data.month);

  numToAlpMonth(monthArr, month);

  const individualArr = MonthsData.data
    .filter((data) => {
      return data.month != 'total';
    })
    .map((data) => data.individual);

  const nonindividualArr = MonthsData.data
    .filter((data) => {
      return data.month != 'total';
    })
    .map((data) => data.nonIndividual);

  const poaArr = MonthsData.data
    ?.filter((data) => {
      return data.month != 'total';
    })
    .map((data) => data.nri);

  data.barData.labels = month;

  data.barData.datasets.map((data) => {
    if (data.label === 'Individual') {
      data.data = individualArr;
    }
    if (data.label === 'Non-Individual') {
      data.data = nonindividualArr;
    }
    if (data.label === 'NRI') {
      data.data = poaArr;
    }
  });
  //month on month bar graph data Initialization end

  //Onboarding Status data Initialization start
  const totalMonthwise = MonthsData.data.filter((data) => {
    return data.month == 'total';
  });
  let Individual = 0;
  let NonIndividual = 0;
  let NRI = 0;
  totalMonthwise.map((total) => {
    Individual = total.individual;
    NonIndividual = total.nonIndividual;
    NRI = total.nri;
  });
  //Onboarding Status data Initialization ends

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <Card raised={true} sx={{ height: { lg: 250 }, p: 2, pt: 1 }}>
          {!MonthsData.year || JSON.stringify(MonthsData) !== JSON.stringify(monthOnMonth) ? (
            <>
              <Skeleton
                sx={{
                  width: { xs: '300px', sm: 415 },
                  ml: { xs: 0, sm: 15, lg: 2 },
                  mr: { xs: 0, sm: 15, lg: 2 },
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: { xs: '300px', sm: 600 },
                    height: { xs: 150, sm: 180 },
                    mt: 1,
                    mb: 2,
                    ml: { xs: 0, sm: 15, lg: 2 },
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <React.Fragment>
                {totalStatus[0]?.individual === 0 &&
                totalStatus[0]?.nonIndividual === 0 &&
                totalStatus[0]?.nri === 0 ? (
                  <>
                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <InfoOutlinedIcon color="info" fontSize="small" />
                      <Typography
                        sx={{
                          color: 'rgba(41, 49, 57, 0.7)',
                          fontSize: 12,
                          fontWeight: 500,
                          ml: 1,
                        }}>
                        Graphical Data is shown only for completed applications .
                      </Typography>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { lg: 18, xs: 12 },
                        fontWeight: 500,
                        display: 'flex',
                        alignItems: 'center',
                        ml: 2,
                      }}>
                      Month on Month
                      <Typography
                        sx={{
                          fontSize: 11,
                          display: 'flex',
                          ml: { lg: 1 },
                          alignItems: 'center',
                          // backgroundColor: 'red',
                          mt: { lg: 0.6 },
                        }}>
                        Last four Months Onboarding count
                      </Typography>
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: 500,
                        color: 'rgb(97, 209, 214)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: { lg: 9, xs: 4.5 },
                        mb: { xs: 4.5 },
                      }}>
                      No Data Available
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <InfoOutlinedIcon color="info" fontSize="small" />
                      <Typography
                        sx={{
                          color: 'rgba(41, 49, 57, 0.7)',
                          fontSize: 12,
                          fontWeight: 500,
                          ml: 1,
                        }}>
                        Graphical Data is shown only for completed applications .
                      </Typography>
                    </Typography>
                    <Typography
                      component={'div'}
                      className="canvas-container"
                      sx={{
                        '&.canvas-container': {
                          position: 'relative',
                          width: { xs: '300px', sm: 'unset' },
                          margin: 'auto',
                        },
                      }}>
                      <Chart
                        type="bar"
                        data={data.barData as ChartData}
                        options={data.barOptions as ChartOptions}
                      />
                    </Typography>
                  </>
                )}
              </React.Fragment>
            </>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={2}>
        <Box
          sx={{
            height: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 0 }}>
              Onboarding Status
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 600,
                color: 'primary.main',
                textAlign: { lg: 'center' },
              }}>
              (Last four Months)
            </Typography>
          </Box>
          <Status
            backgroundColor="rgb(103, 173, 242)"
            text="Individual Investor"
            count={Individual}
          />
          <Status
            backgroundColor="rgb(245, 91, 118)"
            text="Non-Individual Investor"
            count={NonIndividual}
          />
          {/* <Status backgroundColor="rgb(78, 215, 149)" text="NRI Investor" count={NRI} /> */}
        </Box>
      </Grid>
    </>
  );
}
