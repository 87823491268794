import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Applicant } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { KeyValue } from '../ApplicationDetails/contributor';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';
import { InfoDialog, SubHeading } from '../investors/components';
import { accreditedInvestorTypeCapture, currencyConversion } from '../../utils/utilityFunctions';
import { investorTypeMasters, YesNoMaster } from '../../utils/constant';
import { mdmsAccridiatedInfoType } from '../../redux-store/types/mdms';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAccridiatedInfo } from '../../utils/apis/contributor';

export default function ContributorDetails({
  applicants = [],
  applicationSource,
}: {
  applicants: Partial<Applicant>[];
  applicationSource: string | undefined;
}): JSX.Element {
  const [accridiatedInfo, setAccridiatedInfo] = useState<mdmsAccridiatedInfoType>(
    {} as mdmsAccridiatedInfoType
  );
  const accreditedInfoMDMS = useAccridiatedInfo() as mdmsAccridiatedInfoType;
  useEffect(() => {
    if (accreditedInfoMDMS) setAccridiatedInfo(accreditedInfoMDMS);
  }, [accreditedInfoMDMS]);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Contributor Details
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'text.secondary',
                }}>
                Application Source:{' '}
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: 'rgba(0, 0, 0, 0.6)',
                    letterSpacing: '0.4px',
                    ml: 0.5,
                  }}>
                  {applicationSource ? ApplicationSourceMaster[applicationSource] : 'N/A'}
                </Typography>
              </Typography> */}
            </Grid>
            {applicants.map((applicant) => {
              const [open, setOpen] = useState(false);
              const handleClose = () => {
                setOpen(false);
              };
              return (
                <React.Fragment key={applicant.id}>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Name Of Entity'} description={applicant.name} />
                      <KeyValue
                        title={'Date of Registration/Incorporation'}
                        description={formatShortDate(applicant.dateOfBirth)}
                        sx={{ overflowWrap: 'anywhere' }}
                      />
                      <KeyValue
                        title={'Place of Registration/Incorporation'}
                        description={applicant.cityOfIncorporation}
                        sx={{ overflowWrap: 'anywhere' }}
                      />
                      {/* <KeyValue
                        title={'CKYC No./CKYC Acknowledge No./KIN No'}
                        description={applicant.ckycNo}
                      /> */}
                      {applicant.investorType && (
                        <KeyValue
                          title={'Investor Type'}
                          description={investorTypeMasters[applicant.investorType]}
                        />
                      )}
                      <KeyValue title={'Tax ID'} description={applicant.taxIdNumber} />
                      <KeyValue
                        title={'Corporate Identification number (if applicable)'}
                        description={applicant.corporateIdentificationNo}
                      />
                      <Grid item xs={12} sm={12}>
                        <Item>Accredited Investor</Item>
                        <ItemDetails>
                          {YesNoMaster[applicant.isAccreditedInvestor || ''] || '-'}
                        </ItemDetails>
                        {accreditedInvestorTypeCapture(applicant.isAccreditedInvestor || '') && (
                          <Item sx={{ pt: 0 }}>
                            {' '}
                            {accridiatedInfo[applicant.accreditedInvestorType || '']?.label || ''}
                            {accridiatedInfo[applicant.accreditedInvestorType || '']?.info && (
                              <Typography
                                component="span"
                                sx={{
                                  position: 'relative',
                                  left: 5,
                                  bottom: -5,
                                }}>
                                <InfoOutlinedIcon
                                  color="info"
                                  fontSize="small"
                                  onClick={() => setOpen(true)}
                                />
                              </Typography>
                            )}
                            <InfoDialog
                              onClose={handleClose}
                              open={open}
                              content={
                                accridiatedInfo[applicant.accreditedInvestorType || '']?.info || ''
                              }
                              title={
                                accridiatedInfo[applicant.accreditedInvestorType || '']?.label || ''
                              }
                              infoIcon={false}
                            />
                          </Item>
                        )}
                      </Grid>
                    </Grid>
                  </Gridstyles>
                  <SubHeading
                    sx={{
                      color: 'common.black',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    {'Other Details'}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={21}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Gross Annual Income in USD'}
                        description={applicant.grossAnnualIncome}
                      />
                      <Grid item xs={12} sm={6}>
                        <Item>{'Net Worth in USD'}</Item>
                        <ItemDetails> {applicant.netWorth ? applicant.netWorth : '-'}</ItemDetails>
                        {applicant.netWorth && Number(applicant.netWorth) > 0 && (
                          <Item>{currencyConversion(applicant.netWorth)}</Item>
                        )}
                      </Grid>
                      <KeyValue
                        title={'PEP Status'}
                        description={applicant.politicallyExposedPersonStatus}
                      />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
