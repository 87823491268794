import { Box, Button, CardMedia, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendOtp,
  verifyPan,
  EnablePOA,
  IsApplicableForPOA,
  SendEmail,
  AcknowledgeAmlCheck,
  sendConsentEmail,
} from '../../redux-store/actions/onBoarding';
import {
  AcknowledgeAmlCheckResponseBody,
  AmlSystemMatch,
  ApplicationProps,
  EnablePOAResponseBody,
  IsApplicableForPOAResponseBody,
  SendConsentEmailResponseBody,
  SendOtpResponseBody,
  VerifyOtpResponseBody,
  VerifyPanResponseBody,
} from '../../redux-store/types/api-types';
import CloseIcon from '@mui/icons-material/Close';
import { addJointHolder, createApplication } from '../../redux-store/actions/application';
import {
  alphaNumeric,
  emailRegex,
  individualPanRegex,
  nonIndividualPanRegex,
  phoneRegExp,
} from '../../utils/regex';
import { Location } from 'history';
import { Notes } from '../investors/components';
import MFCheckbox from '../../lib/formik/Checkbox';
import { DatePicker } from '../../lib/formik/DatePicker';
import {
  getCountryCodes,
  isMinor,
  maxAge,
  preventSpecialCharacters,
} from '../../utils/utilityFunctions';
import {
  APPLICATION_TYPE,
  ENABLE_Email_Consent,
  ENABLE_EMAIL_OTP,
  IS_DOB_APPLICABLE_FOR_AMC,
  ONBOARDING_INVESTOR,
  ONBOARDING_OPTIONS,
  USER_ROLES,
} from '../../utils/constant';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { AmlPopup, AmlPopupValues, CountryCodesDropDown } from '../commonComponents';
import { Link as RouterLink } from 'react-router-dom';
import UseRadioGroup from '../../lib/formik/Radio';
import { useSnackbar } from 'notistack';
import { RootStateType } from '../../redux-store/reducers';
interface Values {
  taxIdNumber: string;
  dob: string;
  countryCode: string;
  countryNameAndCode: string;
  applicationType: string;
  mobile: string;
  emailId: string;
}

interface MValues {
  pan: string;
  mobile?: string;
  bypassOtp: boolean;
  skipOtpModal: boolean;
  emailId: string;
  hasPOA: boolean;
  dob: string;
  countryCode: string;
  countryNameAndCode: string;
  onboardingOtpEmailConsent?: string;
}
interface EValues {
  pan: string;
  emailId: string;
  dob: string;
}

export default function EnterPAN({
  location,
}: {
  location: Location<{
    firstHolderName: string;
    application: ApplicationProps;
    redirectTo: string;
  }>;
}): JSX.Element {
  const { redirectTo = '', firstHolderName = '', application } = location.state || {};
  const { id, applicants = [], hasPOA } = application || {};
  const dispatch = useDispatch();
  const history = useHistory();

  const applicantPanNumbers = applicants.map((applicant) => applicant.taxIdNumber);
  const applicantMobileNumbers = applicants.map((applicant) => applicant.mobile);
  const validationSchema1 = yup.object().shape({
    taxIdNumber: yup
      .string()
      // .max(20, 'Invalid Tax ID')
      //.matches(alphaNumeric, 'Special characters are not allowed')
      .test('taxIdNumber', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError } = context;
        if (!fieldValue) {
          return createError({
            message: 'Tax Id is required',
            path,
          });
        }
        if (applicantPanNumbers.includes(fieldValue)) {
          return createError({
            message:
              'There is already same Tax Id for an applicant associated with this application',
            path,
          });
        }
        return true;
      }),
    dob: yup.string().test('dob', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, parent } = context;
      if (IS_DOB_APPLICABLE_FOR_AMC) {
        if (!fieldValue) {
          return createError({
            message: 'Date of birth is required',
            path,
          });
        }
        // if (isMinor(fieldValue || '') && parent.pan?.split('')[3] === 'P') {
        //   return createError({
        //     message: 'Age should be greater than 18',
        //     path,
        //   });
        // }
        // if (maxAge(fieldValue || '') && parent.pan?.split('')[3] === 'P') {
        //   return createError({
        //     message: 'Age should be less than 125',
        //     path,
        //   });
        // }
      }

      return true;
    }),
    emailId: yup.string().test('emailId', (value, context) => {
      const { createError, path } = context;
      if (!value) {
        return createError({
          message: 'Email Id is required',
          path,
        });
      }
      if (!emailRegex.test(value)) {
        return createError({
          message: 'Invalid Email Id',
          path,
        });
      }
      return true;
    }),

    mobile: yup.string().test('mobile', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (
        !value &&
        ONBOARDING_INVESTOR[parent.applicationType] === ONBOARDING_INVESTOR.non_individual
      ) {
        return true;
      }
      if (!value) {
        return createError({
          message: 'Mobile number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (applicantMobileNumbers.includes(value)) {
        return createError({
          message:
            'There is already same mobile number for an applicant associated with this application',
          path,
        });
      }
      return true;
    }),
    countryNameAndCode: yup
      .string()
      .nullable()
      .test('countryNameAndCode', (value, context) => {
        const { createError, path, parent } = context;
        const codesList = getCountryCodes()
          .map((codes) => {
            if (codes.label === value) {
              return codes.countryCode;
            }
          })
          .filter((ele) => ele)
          .toString();
        if (
          !value &&
          ONBOARDING_INVESTOR[parent.applicationType] === ONBOARDING_INVESTOR.non_individual
        ) {
          return true;
        }
        if (!value) {
          return createError({
            message: 'Country Code is required',
            path,
          });
        }
        if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }

        return true;
      }),
    applicationType: yup.string().nullable().required('Onboarding Investor Type is required'),
  });
  const initialValues1: Values = {
    taxIdNumber: '',
    dob: '',
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
    applicationType: id && redirectTo ? 'individual' : '',
    mobile: '',
    emailId: '',
  };

  const handleSubmit1 = async (values: Values) => {
    try {
      const {
        taxIdNumber,
        dob,
        applicationType,
        countryCode,
        countryNameAndCode,
        mobile: mobileNumber,
        emailId,
      } = values;

      if (id && redirectTo) {
        const res = (await dispatch(
          addJointHolder(
            {
              taxIdNumber,
              dob,
              applicationType,
              countryCode: countryNameAndCode?.split(':')[1].trim(),
              countryNameAndCode,
              mobileNumber,
              emailId,
            },
            id
          )
        )) as unknown as ApplicationProps;
        history.push(redirectTo, { ...(res || {}) });
        return;
      }
      const res = (await dispatch(
        createApplication({
          taxIdNumber,
          dob,
          applicationType,
          countryCode: countryNameAndCode?.split(':')[1].trim(),
          countryNameAndCode,
          mobileNumber,
          emailId,
        })
      )) as unknown as ApplicationProps;
      ONBOARDING_INVESTOR.individual === ONBOARDING_INVESTOR[applicationType]
        ? history.push('/application/distributor-details', { ...(res || {}) })
        : history.push('/non-individual-application', { ...(res || {}) });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues1}
        validationSchema={validationSchema1}
        onSubmit={handleSubmit1}>
        {({ handleSubmit, resetForm, values, setValues }) => (
          <>
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <MFTextField
                label="Tax Id *"
                name="taxIdNumber"
                placeholder="Enter Tax Id"
                required
                autoComplete="off"
              />
              {IS_DOB_APPLICABLE_FOR_AMC && (
                <DatePicker
                  label={'Date of Birth/Incorporation *'}
                  inputLabelStyles={{
                    transform: 'unset',
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'rgba(0,0,0,0.7)',
                  }}
                  placeholder={'DD/MM/YYYY'}
                  name="dob"
                />
              )}
              <Box sx={{ mt: 1.5 }}>
                <UseRadioGroup
                  formLabel="Onboarding Investor Type *"
                  name={`applicationType`}
                  items={Object.keys(ONBOARDING_INVESTOR)
                    .filter((_option) =>
                      id && redirectTo
                        ? ONBOARDING_INVESTOR[_option] !== ONBOARDING_INVESTOR.non_individual
                        : _option
                    )
                    .map((option) => ({
                      label: ONBOARDING_INVESTOR[option],
                      value: option,
                    }))}
                  onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    setValues({
                      ...values,
                      applicationType: value,
                      mobile: '',
                      countryCode: '+91',
                      countryNameAndCode: 'India: +91',
                    });
                  }}
                />
              </Box>
              {ONBOARDING_INVESTOR[values.applicationType] !==
                ONBOARDING_INVESTOR.non_individual && (
                <MFTextField
                  label="Mobile Number *"
                  name="mobile"
                  placeholder="Enter Mobile number"
                  onKeyDown={(e) => {
                    preventSpecialCharacters(e);
                  }}
                  type="number"
                  startAdornment={
                    <CountryCodesDropDown
                      name={'countryNameAndCode'}
                      value={values.countryNameAndCode}
                    />
                  }
                />
              )}
              <MFTextField
                autoComplete="off"
                // autoFocus
                label="Contact Person Email ID *"
                name="emailId"
                placeholder="Enter Contact Person Email ID"
                required
              />
              <MFSubmitButton label="Proceed" />
            </Box>
            {/* <Dialog open={open} onClose={() => setOpen(false)}>
              <Box
                sx={{
                  p: { xs: 2, sm: 8 },
                  borderRadius: '10px',
                  // width: { xs: '70%', sm: '70%' },
                  maxWidth: 800,
                  height: { xs: '90%', md: 'unset' },
                  overflowY: 'auto',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .MuiButton-root': {
                      minWidth: 200,
                      fontSize: 16,
                    },
                  }}>
                  <Box sx={{ mb: 8 }}>
                    <CardMedia
                      component="img"
                      src="/images/warning-icon-red.svg"
                      sx={{ width: 'unset' }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 500,
                      color: '#337FC9',
                      textAlign: 'center',
                      width: '70%',
                      mb: 5,
                    }}>
                    Unable to verify the details please contact admin
                  </Typography>
                  <Button
                    // component={RouterLink}
                    variant="contained"
                    onClick={() => {
                      resetForm();
                      setOpen(false);
                    }}
                    sx={{ color: 'common.white' }}>
                    Enter another PAN
                  </Button>
                </Box>
              </Box>
            </Dialog> */}
            {/* <AmlPopup
              handleClose={handleClose}
              amlPopupOpen={amlPopup !== null}
              onSubmit={onSubmit}
              FURTHER_INFORMATION={amlFailedresponse?.FURTHER_INFORMATION}
              EXTERNAL_SOURCES={amlFailedresponse?.EXTERNAL_SOURCES}
              displayDocInfo={true}
            /> */}
          </>
        )}
      </Formik>
    </>
  );
}

// export function EnterMobile({
//   location,
// }: {
//   location: Location<{
//     redirectTo: string;
//     panVerificationToken: string;
//     pan: string;
//     firstHolderName: string;
//     application: ApplicationProps;
//     dob: string;
//   }>;
// }): JSX.Element {
//   const {
//     redirectTo = '',
//     panVerificationToken,
//     pan,
//     firstHolderName = '',
//     application,
//     dob,
//   } = location.state || {};
//   const { id, applicants = [] } = application || {};
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const [disablePOA, setDisablePOA] = useState(false);
//   const applicantMobileNumbers = applicants.map((applicant) => applicant.mobile);
//   const { enqueueSnackbar } = useSnackbar();
//   const { role = '' } = useSelector((store: RootStateType) => store.auth);

//   const validationSchema1 = yup.object().shape({
//     pan: yup
//       .string()
//       .uppercase()
//       .length(10, 'PAN must be exactly 10 characters')
//       .test('pan', (value, context) => {
//         const fieldValue = value === undefined ? '' : value;
//         const { path, createError } = context;
//         if (!fieldValue) {
//           return createError({
//             message: 'PAN is required',
//             path,
//           });
//         }
//         if (fieldValue.split('')[3] === 'P' && !individualPanRegex.test(fieldValue)) {
//           return createError({
//             message: 'invalid PAN',
//             path,
//           });
//         }
//         if (!nonIndividualPanRegex.test(fieldValue)) {
//           return createError({
//             message: 'invalid PAN',
//             path,
//           });
//         }
//         return true;
//       }),
//     emailId: yup.string().when('hasPOA', {
//       is: (value: boolean) => (disablePOA ? true : !value) && ENABLE_EMAIL_OTP,
//       then: yup.string().test('emailId', (value, context) => {
//         const { createError, path, parent } = context;
//         if (!value) {
//           return createError({
//             message: 'Email Id is required',
//             path,
//           });
//         }
//         if (!emailRegex.test(value)) {
//           return createError({
//             message: 'Invalid Email Id',
//             path,
//           });
//         }
//         return true;
//       }),
//     }),

//     mobile: yup.string().when('hasPOA', {
//       is: (value: boolean) => (disablePOA ? true : !value),
//       then: yup.string().test('mobile', (value, context) => {
//         const { createError, path, parent } = context;
//         const codesList = getCountryCodes()
//           .map((codes) => {
//             if (codes.label === parent.countryNameAndCode) {
//               return codes.countryCode;
//             }
//           })
//           .filter((ele) => ele)
//           .toString();
//         if (!value) {
//           return createError({
//             message: 'Mobile number is required',
//             path,
//           });
//         }
//         if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
//           return createError({
//             message: 'Invalid Mobile number',
//             path,
//           });
//         }
//         if (!isValidPhoneNumber(value, codesList as CountryCode)) {
//           return createError({
//             message: 'Invalid Mobile number',
//             path,
//           });
//         }
//         if (applicantMobileNumbers.includes(value)) {
//           return createError({
//             message:
//               'There is already same mobile number for an applicant associated with this application',
//             path,
//           });
//         }
//         return true;
//       }),
//     }),
//     countryNameAndCode: yup
//       .string()
//       .nullable()
//       .when('hasPOA', {
//         is: (value: boolean) => (disablePOA ? true : !value),
//         then: yup.string().test('countryNameAndCode', (value, context) => {
//           const { createError, path, parent } = context;
//           const codesList = getCountryCodes()
//             .map((codes) => {
//               if (codes.label === value) {
//                 return codes.countryCode;
//               }
//             })
//             .filter((ele) => ele)
//             .toString();
//           if (!value) {
//             return createError({
//               message: 'Country Code is required',
//               path,
//             });
//           }
//           if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
//             return createError({
//               message: 'Invalid Mobile number',
//               path,
//             });
//           }

//           return true;
//         }),
//       }),
//   });
//   const initialValues1: MValues = {
//     pan: pan,
//     mobile: '',
//     emailId: '',
//     bypassOtp: false,
//     skipOtpModal: false,
//     hasPOA: false,
//     dob: dob,
//     countryCode: '+91',
//     countryNameAndCode: 'India: +91',
//     onboardingOtpEmailConsent: 'otp',
//   };

//   const handleCreateApplication = async (applicantVerificationToken: string) => {
//     const res = (await dispatch(
//       createApplication({ applicantVerificationToken })
//     )) as unknown as ApplicationProps;
//     history.push('/application/distributor-details', { ...(res || {}) });
//   };

//   const handleSubmit1 = async (values: MValues) => {
//     const {
//       pan,
//       mobile = '',
//       bypassOtp = false,
//       hasPOA,
//       emailId,
//       countryCode = '+91',
//       countryNameAndCode = 'India: +91',
//       onboardingOtpEmailConsent = 'otp',
//     } = values;
//     if (hasPOA) {
//       const poaResponse = (await dispatch(
//         EnablePOA({ panVerificationToken, powerOfAttorney: true })
//       )) as unknown as EnablePOAResponseBody;
//       const { applicantVerificationToken } = poaResponse;
//       await handleCreateApplication(applicantVerificationToken);
//       return;
//     }
//     if (ONBOARDING_OPTIONS[onboardingOtpEmailConsent] === ONBOARDING_OPTIONS.email_consent) {
//       (await dispatch(
//         sendConsentEmail({
//           phoneNumber: mobile.trim(),
//           emailId,
//           panVerificationToken,
//           countryCode: countryNameAndCode?.split(':')[1].trim(),
//           countryNameAndCode,
//           applicationId: id || '',
//         })
//       )) as unknown as SendConsentEmailResponseBody;
//       enqueueSnackbar('Email sent successfully', {
//         variant: 'success',
//         autoHideDuration: 3000,
//       });
//       return history.push(
//         [USER_ROLES.AMCAPPROVER, USER_ROLES.POAAPPROVER].includes(role)
//           ? `/applications-list`
//           : '/applications'
//       );
//     }

//     const response = (await dispatch(
//       sendOtp({
//         phoneNumber: mobile.trim(),
//         emailId,
//         panVerificationToken,
//         countryCode: countryNameAndCode?.split(':')[1].trim(),
//         countryNameAndCode,
//         bypassOtp,
//       })
//     )) as unknown as SendOtpResponseBody;

//     const { applicantVerificationToken, phoneNumber } = response || {};
//     if (bypassOtp) {
//       if (id && redirectTo) {
//         (await dispatch(
//           addJointHolder({ applicantVerificationToken }, id)
//         )) as unknown as ApplicationProps;
//         history.push(redirectTo, { id });
//         return;
//       }
//       handleCreateApplication(applicantVerificationToken);
//       return;
//     }
//     history.push('enter-otp', {
//       pan,
//       unmaskedphone: mobile.trim(),
//       umaskedemailId: emailId,
//       mobile: phoneNumber,
//       panVerificationToken,
//       redirectTo,
//       application,
//       email: (response || {}).emailId,
//       firstHolderName,
//       countryCode: countryNameAndCode?.split(':')[1].trim(),
//       countryNameAndCode,
//       ...(response || {}),
//     });
//   };

//   useEffect(() => {
//     (async function () {
//       const res = (await dispatch(
//         IsApplicableForPOA()
//       )) as unknown as IsApplicableForPOAResponseBody;
//       const { powerOfAttorney } = res;
//       setDisablePOA(
//         application
//           ? application.hasPOA
//             ? application.hasPOA
//             : !application.hasPOA
//           : !powerOfAttorney
//       );
//     })();
//   }, []);

//   return (
//     <>
//       <Formik
//         initialValues={initialValues1}
//         validationSchema={validationSchema1}
//         onSubmit={handleSubmit1}>
//         {({
//           handleSubmit,
//           values,
//           validateForm,
//           isSubmitting,
//           setTouched,
//           setErrors,
//           setFieldValue,
//         }) => (
//           <Box component="form" noValidate onSubmit={handleSubmit}>
//             <Box sx={{ position: 'relative' }}>
//               <Box sx={{ position: 'absolute', right: 0, top: 15 }}>
//                 <CardMedia
//                   component="img"
//                   src="/images/tick.svg"
//                   sx={{ width: '18px', float: 'right' }}
//                 />
//               </Box>
//               <MFTextField
//                 label="PAN"
//                 name="pan"
//                 placeholder="Enter PAN"
//                 required
//                 inputProps={{ style: { textTransform: 'uppercase' } }}
//                 disabled={true}
//               />
//               {IS_DOB_APPLICABLE_FOR_AMC && (
//                 <DatePicker
//                   label={'Date of birth'}
//                   inputLabelStyles={{
//                     transform: 'unset',
//                     fontSize: 14,
//                     fontWeight: 500,
//                     color: 'rgba(0,0,0,0.7)',
//                   }}
//                   placeholder={'Enter Date of birth'}
//                   name="dob"
//                   disabled={true}
//                 />
//               )}

//               <MFCheckbox
//                 name="hasPOA"
//                 label="Power of Attorney"
//                 color="primary"
//                 sx={{
//                   '.MuiTypography-root': {
//                     color: 'primary.main',
//                     fontWeight: 500,
//                     fontSize: 14,
//                     letterSpacing: '0.9625px',
//                   },
//                 }}
//                 disabled={disablePOA}
//               />
//             </Box>
//             {!values.hasPOA && (
//               <>
//                 <MFTextField
//                   label="Mobile Number"
//                   name="mobile"
//                   placeholder="Enter Mobile number"
//                   onKeyDown={(e) => {
//                     preventSpecialCharacters(e);
//                   }}
//                   startAdornment={
//                     <CountryCodesDropDown
//                       name={'countryNameAndCode'}
//                       value={values.countryNameAndCode}
//                     />
//                   }
//                 />
//                 {ENABLE_EMAIL_OTP && (
//                   <MFTextField
//                     autoComplete="off"
//                     // autoFocus
//                     label="Email ID"
//                     name="emailId"
//                     placeholder="Enter Email ID"
//                     required
//                   />
//                 )}
//                 {ENABLE_Email_Consent && (
//                   <UseRadioGroup
//                     formLabel=""
//                     name={`onboardingOtpEmailConsent`}
//                     defaultValue="otp"
//                     items={Object.keys(ONBOARDING_OPTIONS).map((option) => ({
//                       label: ONBOARDING_OPTIONS[option],
//                       value: option,
//                     }))}
//                   />
//                 )}
//                 <Notes
//                   displayContent={
//                     'Provide the mobile phone number which is registered in KRA database. Applicants Identity details will be fetched from the KRA database.'
//                   }
//                 />
//               </>
//             )}
//             <MFSubmitButton
//               label={
//                 values.hasPOA
//                   ? 'Continue'
//                   : ONBOARDING_OPTIONS[values.onboardingOtpEmailConsent || ''] ===
//                     ONBOARDING_OPTIONS.email_consent
//                   ? 'Send Email'
//                   : 'Get OTP'
//               }
//             />
//             {!values.hasPOA &&
//               ONBOARDING_OPTIONS[values.onboardingOtpEmailConsent || ''] ===
//                 ONBOARDING_OPTIONS.otp && (
//                 <Button
//                   variant="outlined"
//                   fullWidth
//                   sx={{
//                     color: 'primary.main',
//                     fontWeight: 500,
//                     fontSize: 14,
//                     mt: 0.5,
//                     lineHeight: 1.5,
//                   }}
//                   onClick={async () => {
//                     const error = await validateForm();
//                     if (Object.keys(error).length) {
//                       setTouched(setNestedObjectValues(error, true));
//                       setErrors(error);
//                       return;
//                     }
//                     setFieldValue('skipOtpModal', true);
//                   }}>
//                   Skip OTP Verification
//                 </Button>
//               )}
//             <Dialog open={values.skipOtpModal} onClose={() => setFieldValue('skipOtpModal', false)}>
//               <Box
//                 sx={{
//                   borderRadius: '10px',
//                   // width: { xs: '70%', sm: '70%' },
//                   maxWidth: 800,
//                   height: { xs: '90%', md: 'unset' },
//                   overflowY: 'auto',
//                 }}>
//                 <Box
//                   sx={{
//                     display: 'flex',
//                     flexDirection: 'column',
//                     alignItems: 'center',
//                     justifyContent: 'center',
//                     p: 3,
//                     '& .MuiButton-root': {
//                       minWidth: 200,
//                       fontSize: 16,
//                     },
//                   }}>
//                   <IconButton
//                     onClick={() => setFieldValue('skipOtpModal', false)}
//                     sx={{ alignSelf: 'flex-end' }}>
//                     <CloseIcon fontSize="small" />
//                   </IconButton>
//                   {/* <Box sx={{ mb: 4 }}>
//                     <CardMedia
//                       component="img"
//                       src="/images/warning-icon-red.svg"
//                       sx={{ width: 'unset' }}
//                     />
//                   </Box> */}
//                   <Typography
//                     sx={{
//                       fontSize: 20,
//                       fontWeight: 500,
//                       color: '#337FC9',
//                       textAlign: 'center',
//                       mb: 5,
//                       width: '90%',
//                     }}>
//                     Are you sure you want to Skip OTP Verification?
//                   </Typography>
//                   <Box
//                     sx={{
//                       display: 'flex',
//                       flexWrap: 'wrap',
//                       justifyContent: 'space-around',
//                       width: '100%',
//                     }}>
//                     <Button
//                       variant="outlined"
//                       disabled={isSubmitting}
//                       onClick={() => setFieldValue('skipOtpModal', false)}
//                       sx={{ color: 'primary.main', mb: 2 }}>
//                       Cancel
//                     </Button>
//                     <Button
//                       variant="contained"
//                       sx={{ mb: 2 }}
//                       onClick={() => {
//                         setFieldValue('bypassOtp', true);
//                         handleSubmit();
//                       }}>
//                       Continue
//                     </Button>
//                   </Box>
//                 </Box>
//               </Box>
//             </Dialog>
//           </Box>
//         )}
//       </Formik>
//     </>
//   );
// }

// export function EnterEmailId({
//   location,
// }: {
//   location: Location<{
//     panVerificationToken: string;
//     pan: string;
//     dob: string;
//   }>;
// }): JSX.Element {
//   const { panVerificationToken, pan, dob } = location.state || {};
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const validationSchema1 = yup.object().shape({
//     pan: yup
//       .string()
//       .uppercase()
//       .length(10, 'PAN must be exactly 10 characters')
//       .test('pan', (value, context) => {
//         const fieldValue = value === undefined ? '' : value;
//         const { path, createError } = context;
//         if (!fieldValue) {
//           return createError({
//             message: 'PAN is required',
//             path,
//           });
//         }
//         if (fieldValue.split('')[3] === 'P' && !individualPanRegex.test(fieldValue)) {
//           return createError({
//             message: 'invalid PAN',
//             path,
//           });
//         }
//         if (!nonIndividualPanRegex.test(fieldValue)) {
//           return createError({
//             message: 'invalid PAN',
//             path,
//           });
//         }
//         return true;
//       }),
//     emailId: yup
//       .string()
//       .nullable()
//       .matches(emailRegex, 'invalid Email ID')
//       .required('Email is required'),
//   });
//   const initialValues1: EValues = {
//     pan: pan,
//     emailId: '',
//     dob: dob,
//   };

//   const handleSubmit1 = async (values: EValues) => {
//     const { emailId } = values;
//     const response = (await dispatch(
//       SendEmail({ panVerificationToken, emailId })
//     )) as unknown as VerifyOtpResponseBody;
//     const { applicantVerificationToken } = response || {};
//     const res = (await dispatch(
//       createApplication({ applicantVerificationToken })
//     )) as unknown as ApplicationProps;
//     history.push('/non-individual-application', { ...(res || {}) });
//     // history.push('verify-documents', {
//     //   applicantVerificationToken,
//     // });
//   };

//   return (
//     <>
//       <Formik
//         initialValues={initialValues1}
//         validationSchema={validationSchema1}
//         onSubmit={handleSubmit1}>
//         {({ handleSubmit }) => (
//           <Box component="form" noValidate onSubmit={handleSubmit}>
//             <Box sx={{ position: 'relative' }}>
//               <Box sx={{ position: 'absolute', right: 0, top: 15 }}>
//                 <CardMedia
//                   component="img"
//                   src="/images/tick.svg"
//                   sx={{ width: '18px', float: 'right' }}
//                 />
//               </Box>
//               <MFTextField
//                 label="PAN"
//                 name="pan"
//                 placeholder="Enter PAN"
//                 required
//                 inputProps={{ style: { textTransform: 'uppercase' } }}
//                 disabled={true}
//               />
//               {IS_DOB_APPLICABLE_FOR_AMC && (
//                 <DatePicker
//                   label={'Date of Birth/Incorporation'}
//                   inputLabelStyles={{
//                     transform: 'unset',
//                     fontSize: 14,
//                     fontWeight: 500,
//                     color: 'rgba(0,0,0,0.7)',
//                   }}
//                   placeholder={'Enter Date of birth'}
//                   name="dob"
//                   disabled={true}
//                 />
//               )}
//             </Box>
//             <MFTextField
//               autoComplete="off"
//               autoFocus
//               label="Email ID"
//               name="emailId"
//               placeholder="Enter Email ID"
//               required
//             />

//             <Notes
//               displayContent={
//                 'Based on registered email id, we will fetch the documents available with our MF holding that are serviced by kfintech and the data will be prefilled in the application.'
//               }
//             />
//             <MFSubmitButton label={'Proceed'} />
//           </Box>
//         )}
//       </Formik>
//     </>
//   );
// }
