import { Box, Button, Typography, Hidden, Stack, Switch } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AmcAdminAccess,
  getAmcAdminAccess,
  getAmcAdminList,
  updateAmcAdmin,
} from '../../redux-store/actions/userManagement';
import { AddAmcAdminRequestBody, AmcAdmin } from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { AccessHeader } from './relationShipManagers';
import { ConfirmationDialog } from '../commonComponents';

const columns: ColumnType[] = [
  {
    header: 'Name',
    renderCell: (row: AmcAdmin) => {
      return (
        <Typography
          variant="h5"
          sx={{
            color: '#61D1D6',
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: '14px',
            ':hover': {
              color: 'primary.main',
            },
          }}
          component={RouterLink}
          to={{ pathname: 'amc-admin-details', state: { amcAdminId: row.id } }}>
          {row.user.firstName || 'N/A'}
        </Typography>
      );
    },
  },
  {
    header: 'Mobile Number',
    valueGetter: (row: AmcAdmin) =>
      row.user?.countryCode
        ? row.user?.countryCode + '-' + row.user?.phone
        : row.user.phone || 'N/A',
  },
  {
    header: 'Email ID',
    valueGetter: (row: AmcAdmin) => row.user.email || 'N/A',
  },
];

function amcAdmin(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [amcAdminList, setAmcAdminList] = useState<AmcAdmin[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);
  const [openDialog, setDialog] = useState<{
    amcAdminRowData: AddAmcAdminRequestBody;
  } | null>(null);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(getAmcAdminList())) as unknown as AmcAdmin[];
        const { amcAdminAccess } = (await dispatch(
          getAmcAdminAccess()
        )) as unknown as AmcAdminAccess;
        if (!isComponentAlive) return;
        setAmcAdminList(res);
        setAccessForEditAdd(amcAdminAccess);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const AccessCell = [
    {
      header: AccessHeader,
      renderCell: (row: AddAmcAdminRequestBody): JSX.Element => {
        return (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'baseline', sm: 'center' },
                '.MuiSwitch-root': {
                  paddingLeft: { xs: '0px', sm: '12px' },
                  width: { xs: '52px', sm: '58px' },
                },
                '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
              }}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ amcAdminRowData: row })}
                disabled={!accessForEditAdd}
              />
            </Stack>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 2, sm: 7 },
            pb: { xs: 2, sm: 4 },
          }}>
          <Typography sx={{ fontSize: { xs: 15, sm: 25 }, fontWeight: 500 }}>
            List of AMC Admins
          </Typography>
          {accessForEditAdd && (
            <Hidden only="xs">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{
                  color: 'white',
                  fontWeight: 500,
                  fontSize: 14,
                  mr: 2.5,
                  py: 0.5,
                  px: 3,
                }}
                onClick={() => history.push('add-amc-admin')}>
                Add AMC Admin
              </Button>
            </Hidden>
          )}
          {accessForEditAdd && (
            <Hidden smUp={true}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 13,
                  // mr: 2.5,
                  py: 0.5,
                  // px: 3,
                  // whiteSpace: 'nowrap',
                }}
                onClick={() => history.push('add-amc-admin')}>
                Add AMC Admin
              </Button>
            </Hidden>
          )}
        </Box>
        {useMemo(
          () => (
            <DataTable
              tableData={amcAdminList}
              tableHeader={[...columns, ...AccessCell]}
              currentPage={currentPage}
              isLoading={isLoading}
              noOfPages={1}
              paginationAction={handlePageChange}
              renderAdditionalRow={true}
              tableBodyCustomStyles={{
                '.MuiTableCell-root': {
                  borderBottom: 'none',
                },
              }}
              tableHeaderCustomStyles={{
                '&:last-child th': { border: 0 },
                '.MuiTableCell-head': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'primary.main',
                },
              }}
            />
          ),
          [amcAdminList, currentPage, isLoading]
        )}
        <ConfirmationDialog
          message={`Are you sure you want to ${
            openDialog?.amcAdminRowData.isActive ? 'disable' : 'enable'
          } the access to this user ?`}
          open={openDialog !== null}
          setOpen={() => setDialog(null)}
          onSave={async () => {
            setDialog(null);
            try {
              if (openDialog) {
                const updatedResponse = (await dispatch(
                  updateAmcAdmin(openDialog.amcAdminRowData.id as number, {
                    ...openDialog.amcAdminRowData,
                    isActive: !openDialog?.amcAdminRowData.isActive,
                  })
                )) as unknown as AmcAdmin;
                setAmcAdminList(
                  amcAdminList?.map((amcAdmin) => {
                    if (Number(amcAdmin.id) === Number(updatedResponse.id)) {
                      return {
                        ...amcAdmin,
                        ...updatedResponse,
                      };
                    }
                    return amcAdmin;
                  })
                );
              }
            } catch (e) {
              console.error((e as Error).message);
            }
          }}
          onCancel={() => setDialog(null)}
        />
      </Box>
    </>
  );
}

export default amcAdmin;
