import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Bank } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles } from './index';
import { SubHeading } from '../investors/components';
import { getBankAddress } from '../../utils/utilityFunctions';
import { BankAccountTypeForVenture } from '../../utils/constant';

export default function BankDetails({ banks = [] }: { banks: Partial<Bank>[] }): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Investor&apos;s Bank Details
            </Typography>
            {banks.length === 0 ? (
              <Grid
                xs={12}
                sm={12}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'common.black',
                  textAlign: 'center',
                  my: 3,
                }}>
                No Bank Details
              </Grid>
            ) : (
              banks.map((bank, index: number) => {
                const title = ['Bank 1 Details', 'Bank 2 Details', 'Bank 3 Details'][index];
                const bankAddress = getBankAddress(bank.address1, bank.address2, bank.address3);
                return (
                  <React.Fragment key={bank.id}>
                    <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                    <Gridstyles>
                      <Grid
                        item
                        container
                        pt={2}
                        pb={5}
                        rowSpacing={6}
                        columnSpacing={13}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start">
                        <KeyValue title={'SWIFT Code/IFSC'} description={bank.swiftCode} />
                        <KeyValue
                          title={'Bank Account Number'}
                          description={bank.bankAccountNumber}
                        />
                        <KeyValue
                          title={'Account Type'}
                          description={BankAccountTypeForVenture[bank?.bankAccountType || '']}
                        />
                        <KeyValue title={'Bank Name'} description={bank.bankName} />
                        <KeyValue title={'Bank Branch'} description={bank.bankBranch} />
                        <KeyValue
                          title={`Bank Address`}
                          description={`${bankAddress}`}
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        />
                        <KeyValue
                          title={'IBAN (International Bank Account Number)'}
                          description={bank.ibanNumber}
                        />
                        <KeyValue
                          title={'CIF (Customer Information File) if any'}
                          description={bank.cif}
                        />
                        {/* <KeyValue
                          title={`Default Bank Account`}
                          description={bank.defaultBankAccount ? 'Yes' : '-'}
                        /> */}
                      </Grid>
                    </Gridstyles>
                  </React.Fragment>
                );
              })
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
