import { Grid, Typography } from '@mui/material';
import MFSelectField from '../../lib/formik/SelectField';
import { ProceedSaveLater, SubHeading } from '../investors/components';
import { MFTextField } from '../../lib/formik';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { Applicant } from '../../redux-store/types/api-types';
import {
  grossAnnualMasters,
  investorTypeMasters,
  PEPsMasters,
  YesNoMaster,
} from '../../utils/constant';
import { DatePicker } from '../../lib/formik/DatePicker';
import React, { useEffect, useState } from 'react';
import {
  accreditedInvestorTypeCapture,
  applicationComparison,
  currencyConversion,
  folioValidationCheck,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { NonIndividualContributorValidationSchema } from '../../utils/schema';
import { useSnackbar } from 'notistack';
import UseRadioGroup, { UseRadioGroupTransparent } from '../../lib/formik/Radio';
import { mdmsAccridiatedInfoType } from '../../redux-store/types/mdms';
import { showError } from '../../redux-store/actions/auth';
import { useAccridiatedInfo } from '../../utils/apis/contributor';

const contributorObject: Partial<Applicant> = {
  name: '',
  cityOfIncorporation: '',
  ckycNo: '',
  investorType: '',
  dateOfBirth: '',
  panNo: '',
  grossAnnualIncome: '',
  netWorth: '',
  taxIdNumber: '',
  corporateIdentificationNo: '',
  politicallyExposedPersonStatus: '',
  accreditedInvestorType: '',
  isAccreditedInvestor: '',
};

export type Values = {
  saveType: string;
  modeOfHolding: string;
  applicationSource: string;
  folio_no: string | null;
  applicants: Partial<Applicant>[];
};

const initialValues: Values = {
  modeOfHolding: 'single',
  applicants: [contributorObject],
  applicationSource: 'digitalonboarding',
  folio_no: '',
  saveType: 'save and proceed',
};

export default function ContributorDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [contributorValues, setContributorValues] = useState(initialValues);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [accridiatedInfo, setAccridiatedInfo] = useState<mdmsAccridiatedInfoType>(
    {} as mdmsAccridiatedInfoType
  );
  const accridiatedInfoMasters = useAccridiatedInfo() as mdmsAccridiatedInfoType;
  useEffect(() => {
    if (accridiatedInfoMasters) setAccridiatedInfo(accridiatedInfoMasters);
    try {
      const {
        applicants = [],
        applicationSource = 'digitalonboarding',
        folio_no = '',
      } = application || {};
      setContributorValues({
        ...contributorValues,
        folio_no: folio_no,
        applicationSource: applicationSource || 'digitalonboarding',
        applicants: applicants.length
          ? applicants.map((applicant) => ({
              ...applicant,
              isAccreditedInvestor: applicant.isAccreditedInvestor || '',
              accreditedInvestorType: accreditedInvestorTypeCapture(
                applicant.isAccreditedInvestor || ''
              )
                ? applicant.accreditedInvestorType
                : '',
            }))
          : [contributorObject],
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  }, [application, accridiatedInfoMasters]);

  const handleApplicant = async (values: Values) => {
    const { saveType, applicants = [], folio_no, applicationSource } = values;
    const { id, currentStep, banks = [] } = application || {};
    // const isAllBanksPennyChecked = banks.length
    //   ? banks.every((bank) => bank.pennydropCheck)
    //   : false;
    // const contributorDetailsFieldsCheck = application?.applicants
    //   .map((applicant, index) => {
    //     return applicants.map((_applicant, ind) => {
    //       if (index === ind) {
    //         const { investorType } = _applicant;
    //         if (investorType !== applicant.investorType) {
    //           return 'true';
    //         }
    //         return 'false';
    //       }
    //       return 'false';
    //     });
    //   })
    //   .flat();
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants
          .map((applicant) => ({
            ...applicant,
            name: removeSingleQuote(applicant.name),
            cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
          }))
          ?.sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        folio_no: folioValidationCheck(applicationSource) ? folio_no : '',
        applicationSource,
        banks:
          values.applicants[0].name?.split("'").join('').trim() ===
          application?.applicants[0].name?.split("'").join('').trim()
            ? banks
            : banks.map((bank) => ({
                ...bank,
                pennydropCheck: false,
                pennydropVerified: false,
              })),
        applicants: values.applicants
          .map((applicant) => ({
            ...applicant,
            name: removeSingleQuote(applicant.name),
            cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
            isAccreditedInvestor: applicant.isAccreditedInvestor,
            accreditedInvestorType: accreditedInvestorTypeCapture(
              applicant.isAccreditedInvestor || ''
            )
              ? applicant.accreditedInvestorType
              : '',
          }))
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        modeOfHolding: 'single',
        currentStep: !!currentStep && currentStep > 1 ? currentStep : Number(currentStep) + 1,
      }
    );

    // values?.applicants?.forEach((applicant) => {
    //   if (applicant.isAccreditedInvestor?.toString() === 'no') {
    //     throw 'Non-accredited investor is not allowed to proceed further';
    //   }
    // });

    if (id && !checkApplication) {
      await dispatch(
        updateApplication({
          body: {
            ...application,
            banks:
              values.applicants[0].name?.split("'").join('').trim() ===
              application?.applicants[0].name?.split("'").join('').trim()
                ? banks
                : banks.map((bank) => ({
                    ...bank,
                    pennydropCheck: false,
                    pennydropVerified: false,
                  })),
            applicants: values.applicants.map((applicant) => ({
              ...applicant,
              name: removeSingleQuote(applicant.name),
              cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
              isAccreditedInvestor: applicant.isAccreditedInvestor,
              accreditedInvestorType: accreditedInvestorTypeCapture(
                applicant.isAccreditedInvestor || ''
              )
                ? applicant.accreditedInvestorType
                : '',
            })),
            modeOfHolding: 'single',
            folio_no: folioValidationCheck(applicationSource) ? folio_no : '',
            applicationSource,
            currentStep: 2,
            // !!currentStep && currentStep > 1
            //   ? (values.applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //       isAllBanksPennyChecked) ||
            //     (contributorDetailsFieldsCheck as unknown as string[])?.includes('true')
            //     ? 2
            //     : currentStep
            //   : (values.applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //       isAllBanksPennyChecked) ||
            //     (contributorDetailsFieldsCheck as unknown as string[])?.includes('true')
            //   ? 2
            //   : Number(currentStep) + 1,
          },
          applicationId: id,
          ...(saveType !== 'save and proceed' && {
            toastMessage: '',
          }),
        })
      );
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      const { id, applicant1ReferenceId = '', applicationNumber } = application || {};
      const { saveType } = values;
      await handleApplicant(values);
      saveType === 'save and proceed'
        ? history.push('contact-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
      if (saveType !== 'save and proceed') {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      //history.push(`document-details`, { applicationType: 'non_individual' });
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
      typeof e === 'string' && dispatch(showError(e));
    }
  };

  return (
    <Formik
      initialValues={contributorValues}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            NonIndividualContributorValidationSchema(accridiatedInfo),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues, errors }) => (
        <>
          <Grid
            container
            rowSpacing={1}
            //columnSpacing={5}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            {/* <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }} mb={2}>
              <UseRadioGroup
                formLabel="Application Source"
                name="applicationSource"
                defaultValue="digitalonboarding"
                items={Object.keys(ApplicationSourceMaster).map((source) => ({
                  label: ApplicationSourceMaster[source],
                  value: source,
                }))}
              />
            </Grid> */}
            {folioValidationCheck(values.applicationSource) && (
              <Grid item xs={12} sm={6} mb={2}>
                <MFTextField
                  name={`folio_no`}
                  label={`Folio Number ${
                    folioValidationCheck(values.applicationSource) ? '*' : ''
                  }`}
                  placeholder="Enter Folio Number"
                />
              </Grid>
            )}
            {values.applicants.map((applicant, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.name`}
                    label="Name of Entity *"
                    placeholder="Enter Name of Entity"
                    disabled={applicant.dataFetchedFromKRA || false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Date of Registration/Incorporation *'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    placeholder={'DD/MM/YYYY'}
                    name={`applicants.${index}.dateOfBirth`}
                    disabled={applicant.dataFetchedFromKRA || false}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.cityOfIncorporation`}
                    label="Place of Registration/Incorporation *"
                    placeholder="Enter Place of Registration/Incorporation"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.corporateIdentificationNo`}
                    label="Corporate Identification number (if applicable)"
                    placeholder="Enter Corporate Identification number (if applicable)"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.investorType`}
                    label="Investor Type *"
                    items={Object.keys(investorTypeMasters).map((investor) => ({
                      key: investorTypeMasters[investor],
                      value: investor,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.taxIdNumber`}
                    label="Tax ID  *"
                    placeholder="Enter Tax ID "
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} sx={{ mt: 1.5 }}>
                  <UseRadioGroup
                    formLabel="Accredited Investor *"
                    name={`applicants.${index}.isAccreditedInvestor`}
                    items={Object.keys(YesNoMaster).map((option) => ({
                      label: YesNoMaster[option],
                      value: option,
                    }))}
                  />
                </Grid>
                {accreditedInvestorTypeCapture(
                  values.applicants[index].isAccreditedInvestor || ''
                ) && (
                  <Grid item xs={12} sm={12} sx={{ mt: 1.5 }}>
                    <UseRadioGroupTransparent
                      name={`applicants.${index}.accreditedInvestorType`}
                      items={Object.keys(accridiatedInfo).map((option) => ({
                        label: accridiatedInfo[option].label,
                        info: accridiatedInfo[option]?.info,
                        value: option,
                      }))}
                      row={false}
                    />
                  </Grid>
                )}
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Other Details
                </SubHeading>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.grossAnnualIncome`}
                    label="Gross Annual Income in USD"
                    items={grossAnnualMasters.map((grossIncome) => ({
                      key: grossIncome,
                      value: grossIncome,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.netWorth`}
                    label="Net Worth in USD"
                    placeholder="Enter Net Worth in USD"
                  />
                  {values.applicants[index].netWorth &&
                    !isNaN(Number(values.applicants[index].netWorth)) && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.applicants[index].netWorth)}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.politicallyExposedPersonStatus`}
                    label="Politically Exposed Person (PEP) Status"
                    items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
                    disabled={true}
                  />
                </Grid>
              </React.Fragment>
            ))}
            <ProceedSaveLater
              saveLater={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
        </>
      )}
    </Formik>
  );
}
