import React from 'react';
import { exitLoadType } from '../../redux-store/types/funds';
import { Box, Grid } from '@mui/material';
import { WebViewDataRow } from '../DataTable';
import { SubHeading } from '../investors/components';

const headers = [
  {
    header: 'Period (after expiry of Lock-in Period)',
    label: 'duration',
    valueGetter: (row: exitLoadType) => row.duration || 'N/A',
  },
  {
    header: 'Applicable Exit Charge',
    label: 'exitLoad',
    valueGetter: (row: exitLoadType) => row.exitLoad || 'N/A',
  },
];

export default function ExitLoadDetails({
  exitLoadDetails,
}: {
  exitLoadDetails: exitLoadType[];
}): JSX.Element {
  return (
    <>
      {exitLoadDetails?.length ? (
        <Grid container pt={3}>
          <SubHeading
            sx={{
              color: 'common.black',
              letterSpacing: 0.5 + 'px',
              padding: { xs: '10px 10px', sm: '10px 30px' },
            }}>
            Exit Load Details
          </SubHeading>
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              width: '100%',
            }}>
            <Grid xs={12} alignSelf="center">
              <WebViewDataRow
                // groupIndex={index}
                tableData={exitLoadDetails}
                tableHeader={headers}
                renderAdditionalRow={false}
                tableHeaderCustomStyles={{
                  '.MuiTableCell-head': {
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'primary.main',
                  },
                }}
                rowCustomStyles={{
                  '.MuiTableCell-root': {
                    py: '8px',
                    overflowWrap: 'anywhere',
                    padding: { xs: '10px', sm: '30px' },
                  },
                }}
                tableBodyCustomStyles={{
                  '.MuiTableRow-root': {
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  },
                }}
              />
            </Grid>
          </Box>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
