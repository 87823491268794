import { Box, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FatcaMdmsData } from '../../redux-store/actions/application';
import {
  Applicant,
  FatcaMdms,
  nonIndividualMdmsQuestionsFatca,
} from '../../redux-store/types/api-types';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import {
  AssignAnsAndVisibilityForInitialFatca,
  BorderedTextField,
  Group,
  OpenTextField,
  SectionHeader,
  SingleChoiceRadio,
} from '../NonIndividualInvestor/fatca';
import { useNationalityList } from '../../utils/apis/contributor';

interface Values {
  applicants: {
    fatcadetail: nonIndividualMdmsQuestionsFatca;
    wishToUploadFatcaDocument: boolean | null;
  }[];
}

export default function Fatca({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  const [fatcaData, setFatcaData] = useState<Values>();
  const dispatch = useDispatch();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const nationalitiesMdmsMasters = useNationalityList() as unknown as nationaliyType;

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(FatcaMdmsData())) as unknown as FatcaMdms;
        if (nationalitiesMdmsMasters) setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        if (response.fatca_questions) {
          const _applicants = {
            applicants: applicants.map((applicant) => {
              const { fatcadetail: existingFatcaDetails = null } = applicant || {};
              return {
                fatcadetail: existingFatcaDetails
                  ? AssignAnsAndVisibilityForInitialFatca(
                      response.fatca_questions,
                      existingFatcaDetails
                    )
                  : [],
                wishToUploadFatcaDocument: applicant.wishToUploadFatcaDocument,
              };
            }),
          };
          setFatcaData(_applicants as Values);
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [nationalitiesMdmsMasters]);
  return (
    <Box
      sx={{
        bgcolor: 'white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
      }}
      mt={5}>
      <Box sx={{ p: 2 }}>
        <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
          <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 3 }}>
            Fatca Details
          </Typography>
          <Formik
            initialValues={fatcaData as Values}
            enableReinitialize={true}
            onSubmit={() => {
              console.log('this action should not be trigered');
            }}>
            {({ values }) => (
              <Grid
                container
                rowSpacing={1}
                //columnSpacing={5}
                sx={{
                  width: '100%',
                  ml: 0,
                  '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
                }}>
                {values?.applicants.map((applicant, idx) => (
                  <React.Fragment key={idx}>
                    {!applicant.wishToUploadFatcaDocument && applicant.fatcadetail.length === 0 && (
                      <Grid
                        xs={12}
                        sm={12}
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'common.black',
                          textAlign: 'center',
                          my: 3,
                        }}>
                        No Fatca Details
                      </Grid>
                    )}
                    {applicant.wishToUploadFatcaDocument && (
                      <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                        {/* <Checkbox defaultChecked disabled={true} /> */}
                        <Typography
                          sx={{
                            fontSize: 15,
                            fontWeight: 700,
                            color: 'rgba(0, 0, 0, 0.6)',
                            letterSpacing: '0.5px',
                          }}>
                          {`Wish to upload FATCA-CRS Declaration`}
                        </Typography>
                      </Grid>
                    )}
                    {!applicant.wishToUploadFatcaDocument &&
                      applicant.fatcadetail.map((item) => (
                        <React.Fragment key={item.id}>
                          {item.isVisible &&
                            item.section_header &&
                            item.question_type !== 'group' && (
                              <SectionHeader value={item.section_header} />
                            )}
                          {item.isVisible && item.question_type === 'single_choice_radio' && (
                            <SingleChoiceRadio
                              data={item}
                              values={applicant.fatcadetail}
                              formValuesIndex={idx}
                              questionId={item.id}
                              disabled={true}
                            />
                          )}
                          {item.isVisible && item.question_type === 'group' && (
                            <Group
                              data={item}
                              formValuesIndex={idx}
                              values={applicant.fatcadetail}
                              _applicants={applicants}
                              disabled={true}
                              nationalityDropdown={mdmsCountriesList}
                            />
                          )}
                          {item.isVisible && item.question_type === 'open_text_field' && (
                            <OpenTextField
                              text={item.question_text}
                              applicantsIndex={idx}
                              questionId={item.id}
                              placeHolder={item?.placeholder || 'Please enter text here'}
                              isMandatory={item.isMandatory}
                              disabled={true}
                            />
                          )}
                          {item.isVisible && item.question_type === 'bordered_text_field' && (
                            <Grid item xs={12} sm={6} sx={{ pr: { xs: 0, sm: '30px' }, pt: 2 }}>
                              <BorderedTextField
                                applicantsIndex={idx}
                                questionId={item.id}
                                label={item.question_text}
                                placeHolder={item?.placeholder || 'Please enter text here'}
                                isMandatory={item.isMandatory}
                                disabled={true}
                              />
                            </Grid>
                          )}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </Grid>
            )}
          </Formik>
        </Grid>
      </Box>
    </Box>
  );
}
