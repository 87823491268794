import { useState, useEffect } from 'react';
import { getAccridiatedInfo, getNationalityList } from '../../redux-store/actions';
import { useDispatch } from 'react-redux';
import { mdmsAccridiatedInfoType, nationaliyType } from '../../redux-store/types/mdms';
import { showError } from '../../redux-store/actions/auth';

export const useNationalityList = (): nationaliyType | undefined => {
  const [data, setData] = useState<nationaliyType | undefined>();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchNationalityList = async () => {
      try {
        const response = await dispatch(getNationalityList());
        setData(response as unknown as nationaliyType);
      } catch (e) {
        console.error((e as Error).message);
        typeof e === 'string' && dispatch(showError(e as string));
      }
    };
    if (!data) fetchNationalityList();
  }, [data]);
  return data;
};

export const useAccridiatedInfo = (): mdmsAccridiatedInfoType | undefined => {
  const [data, setData] = useState<mdmsAccridiatedInfoType | undefined>();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchAccridiatedInfo = async () => {
      try {
        if (data) {
          return;
        }
        const response = await dispatch(getAccridiatedInfo());
        setData(response as unknown as mdmsAccridiatedInfoType);
      } catch (e) {
        console.error((e as Error).message);
        typeof e === 'string' && dispatch(showError(e as string));
      }
    };
    if (!data) fetchAccridiatedInfo();
  }, [data]);
  return data;
};
