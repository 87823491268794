/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../lib/formik';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MFCheckbox from '../../lib/formik/Checkbox';
import { NomineeType } from '../../redux-store/types/api-types';
import {
  applicationComparison,
  applyRoleBasedStatus,
  EIGHTEEN_YEARS_IN_MILLISECONDS,
  getApplicantName,
  getNomineeRelation,
  isMinor,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory } from 'react-router';
import { updateApplication } from '../../redux-store/actions/application';
import { DatePicker } from '../../lib/formik/DatePicker';
import { ConfirmationDialog } from '../commonComponents';
import { nomineeDetailsSchema } from '../../utils/schema';
import MFSelectField from '../../lib/formik/SelectField';
import { AMC_APPROVER_CHECK_FOR_INDIVIDUAL, NomineeRelations } from '../../utils/constant';
import { Notes } from './components';
import { useSnackbar } from 'notistack';

type updateNominee = NomineeType & {
  Relationship: string | null;
};

type Values = {
  doNotWishToNominate: boolean;
  nominees: updateNominee[];
  saveType: string;
};

const newNominee: updateNominee = {
  nomineeName: '',
  nomineeRelationship: '',
  dateOfBirth: null,
  nomineePercentage: 0,
  guardianName: '',
  isActive: true,
  Relationship: '',
  taxIdNumber: '',
  dateOfAttainingMajority: null,
  guardianAddress: '',
  guardianTaxIdNumber: '',
};

const initialValues: Values = {
  doNotWishToNominate: true,
  nominees: [newNominee],
  saveType: 'save and proceed',
};

const Nominee = ({
  nomineeName,
  index,
  values,
}: {
  nomineeName: number;
  index: number;
  values: Values;
}): JSX.Element => {
  const { dateOfBirth = '' } = values.nominees[index];
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineeName`}
          label={`Nominee Name - ${nomineeName} *`}
          placeholder={`Enter Nominee Name - ${nomineeName}`}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`nominees.${index}.Relationship`}
          label="Nominee Relationship with the Security Holder *"
          items={NomineeRelations.filter((relation) => {
            const { nominees } = values;
            const selectedRelations = nominees
              .filter(
                (nominee, ind) =>
                  ind !== index &&
                  nominee.Relationship &&
                  ['MOTHER', 'FATHER', 'SPOUSE'].includes(nominee.Relationship)
              )
              .map((nominee) => nominee.Relationship);
            return !selectedRelations.includes(relation);
          }).map((relation) => ({
            key: relation,
            value: relation,
          }))}
        />
        {values.nominees.map((nominee, idx: number) => {
          if (nominee.Relationship === 'OTHERS' && idx === index) {
            return (
              <MFTextField
                name={`nominees.${index}.nomineeRelationship`}
                label="Please Specify Relationship *"
                placeholder={`Enter Nominee Relationship`}
              />
            );
          }
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineePercentage`}
          label="Nominee % *"
          placeholder="Enter Nominee %"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.taxIdNumber`}
          label="Nominee Country tax identification number"
          placeholder="Enter Nominee Country tax identification number"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          label={'Nominee Date of Birth *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Nominee Date of Birth'}
          name={`nominees.${index}.dateOfBirth`}
        />
      </Grid>
      {isMinor(dateOfBirth ? dateOfBirth : '') && (
        <>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label={'Date of Attaining Majority *'}
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              placeholder={'Enter Date of Attaining Majority'}
              name={`nominees.${index}.dateOfAttainingMajority`}
              maxDate={
                new Date(
                  `${new Date(dateOfBirth || '').getFullYear() + 18}-${
                    new Date(dateOfBirth || '').getMonth() + 1
                  }-${new Date(dateOfBirth || '').getDate()}`
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.guardianName`}
              label="Guardian Name *"
              placeholder="Enter Guardian Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.guardianTaxIdNumber`}
              label="Guardian Country tax identification number *"
              placeholder="Enter Guardian Country tax identification number"
            />
          </Grid>
          <Grid item xs={12}>
            <MFTextField
              name={`nominees.${index}.guardianAddress`}
              label="Guardian Address *"
              placeholder="Enter Guardian Address"
              multiline
              rows={3}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default function NomineeDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [nomineesData, setNomineesData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [nomineeToDelete, setNomineeToDelete] = useState<{
    nominee: Partial<NomineeType>;
    index: number;
  } | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values: Values) => {
    try {
      const {
        nominees: existingNominees = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { nominees, doNotWishToNominate, saveType } = values;
      const updatedPayload = {
        ...application,
        doNotWishToNominate,
        nominees: doNotWishToNominate
          ? []
          : nominees.map((nominee, index) => {
              const { id = null } = existingNominees[index] || {};
              if (nominee.id === id) {
                const { Relationship, ...rest } = nominee;
                return {
                  ...(existingNominees[index] || {}),
                  ...rest,
                  nomineeRelationship: getNomineeRelation(Relationship)
                    ? Relationship
                    : removeSingleQuote(nominee.nomineeRelationship),
                  dateOfBirth: !nominee.dateOfBirth
                    ? null
                    : new Date(nominee.dateOfBirth).toISOString(),
                  dateOfAttainingMajority: !nominee.dateOfAttainingMajority
                    ? null
                    : new Date(nominee.dateOfAttainingMajority).toISOString(),
                  isActive: nominee.isActive && !doNotWishToNominate,
                  nomineeName: removeSingleQuote(rest.nomineeName),
                  guardianName: removeSingleQuote(rest.guardianName),
                  guardianAddress: removeSingleQuote(rest.guardianAddress),
                };
              }
              const { Relationship, ...rest1 } = nominees[index];
              return {
                ...rest1,
                nomineeRelationship: removeSingleQuote(rest1.nomineeRelationship),
                nomineeName: removeSingleQuote(rest1.nomineeName),
                guardianName: removeSingleQuote(rest1.guardianName),
              };
            }),
      };
      const checkApplication = applicationComparison(
        {
          ...application,
          nominees: application?.nominees?.sort(
            (nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)
          ),
        },
        {
          ...updatedPayload,
          nominees: updatedPayload.nominees?.sort(
            (nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)
          ),
          currentStep: !!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 6,
              //!!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    const { nominees = [], doNotWishToNominate = true } = application || {};
    setNomineesData({
      ...nomineesData,
      doNotWishToNominate: nominees.length
        ? false
        : doNotWishToNominate === null
        ? true
        : doNotWishToNominate,
      nominees: nominees.length
        ? nominees.map((nominee) => ({
            ...nominee,
            Relationship: getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
              ? nominee.nomineeRelationship
                ? nominee.nomineeRelationship.toUpperCase()
                : nominee.nomineeRelationship
              : 'OTHERS',
            nomineeRelationship: getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
              ? ''
              : nominee.nomineeRelationship,
          }))
        : [],
    });
  }, [application]);

  return (
    <Formik
      initialValues={nomineesData}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, nomineeDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes displayContent={'Contributor(s) have the option to choose upto three nominees'} />
          {values.nominees.map((nominee, index) => (
            <React.Fragment key={index}>
              <SubHeading
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}>
                {getApplicantName(index + 1, true)} Nominee Details
                <Box
                  sx={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => setNomineeToDelete({ nominee, index })}>
                  <Typography
                    sx={{
                      color: 'rgba(196, 42, 51, 0.8)',
                      fontSize: 12,
                      ml: 'auto',
                      letterSpacing: '-0.2px',
                      cursor: 'pointer',
                    }}>
                    Remove Nominee{' '}
                  </Typography>
                  <IconButton sx={{ p: '2px' }}>
                    <CardMedia
                      component="img"
                      src="/images/delete-red.svg"
                      alt="Delete Icon"
                      sx={{ width: 'unset' }}
                    />
                  </IconButton>
                </Box>
              </SubHeading>
              <Nominee nomineeName={index + 1} index={index} key={index} values={values} />
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <MFCheckbox
              disabled={!!values.nominees.length}
              name="doNotWishToNominate"
              label="I do not wish to nominate."
              sx={{ letterSpacing: '1px' }}
              onChange={({ target: { checked } }) => {
                const updatedNominees = !checked
                  ? [...values.nominees, newNominee]
                  : values.nominees;
                setValues({
                  ...nomineesData,
                  doNotWishToNominate: checked,
                  nominees: updatedNominees.map((nominee) => ({
                    ...nominee,
                    nomineePercentage: 100,
                    isActive: !checked,
                  })),
                });
              }}
              checked={values.doNotWishToNominate}
            />
          </Grid>
          {values.nominees.length < 3 && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 14,
                  mt: 2,
                }}
                onClick={() =>
                  setValues({
                    ...values,
                    nominees: [
                      ...values.nominees,
                      { ...newNominee, nomineePercentage: values.nominees.length ? 0 : 100 },
                    ],
                    doNotWishToNominate: false,
                  })
                }>
                Add Nominee
              </Button>
            </Grid>
          )}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                nominees: values.nominees.map((nominee) => ({
                  ...nominee,
                  nomineeRelationship:
                    nominee.Relationship === 'OTHERS'
                      ? nominee.nomineeRelationship
                      : nominee.Relationship,
                })),
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                nominees: values.nominees.map((nominee) => ({
                  ...nominee,
                  nomineeRelationship:
                    nominee?.Relationship === 'OTHERS'
                      ? nominee.nomineeRelationship
                      : nominee?.Relationship,
                })),
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
          <ConfirmationDialog
            message={'Are you sure you want to delete nominee ?'}
            open={nomineeToDelete !== null}
            setOpen={() => setNomineeToDelete(null)}
            onSave={async () => {
              try {
                const { id } = application || {};
                const remainedApplicants = values.nominees.map((nominee, i) => {
                  if (i === nomineeToDelete?.index) {
                    return {
                      ...nominee,
                      isActive: false,
                    };
                  }
                  return nominee;
                });
                const sortedNominees = remainedApplicants.filter((applicant) => applicant.isActive);
                setNomineeToDelete(null);
                if (id && nomineeToDelete?.nominee.id) {
                  await dispatch(
                    updateApplication({
                      body: {
                        ...application,
                        nominees: remainedApplicants.map((nominee: updateNominee) => {
                          const { Relationship, ...rest } = nominee;
                          return {
                            ...rest,
                            nomineeRelationship: getNomineeRelation(Relationship)
                              ? Relationship
                              : nominee.nomineeRelationship,
                          };
                        }),
                        doNotWishToNominate:
                          sortedNominees.length === 0 ? true : values.doNotWishToNominate,
                        status:
                          !application?.hasPOA &&
                          AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                          application?.status !== 'draft' &&
                          applyRoleBasedStatus(role)
                            ? 'sent_to_amc_approver'
                            : application?.status,
                      },
                      applicationId: id,
                    })
                  );
                  return;
                }
                setValues({
                  ...values,
                  nominees: sortedNominees,
                  doNotWishToNominate:
                    sortedNominees.length === 0 ? true : values.doNotWishToNominate,
                });
              } catch (e) {
                console.error((e as Error).message);
              }
            }}
            onCancel={() => setNomineeToDelete(null)}
          />
        </Grid>
      )}
    </Formik>
  );
}
