/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as yup from 'yup';
import {
  Bank,
  ControllingPersons,
  Groups,
  NomineeType,
  nonIndividualQuestionsFatca,
  ubo,
  Applicant,
} from '../redux-store/types/api-types';
import {
  accountNumberRegex,
  ifscRegex,
  stringRegex,
  addressRegex,
  phoneRegExp,
  nonIndividualPanRegex,
  emailRegex,
  individualPanRegex,
  numberRegex,
  amountRegex,
  alphaNumeric,
  indianPin,
  arnCodeRegex,
} from './regex';
import {
  isMinor,
  currencyConversion,
  maxAge,
  getCountryCodes,
  checkForCorrespondenceAddress,
  EIGHTEEN_YEARS_IN_MILLISECONDS,
  folioValidationCheck,
  isFieldApplicable,
  accreditedInvestorTypeCapture,
} from './utilityFunctions';
import { InternalOptions } from 'yup/lib/types';
import { Values } from '../components/investors/contactDetails';
import { Distributor, SubDistributor } from '../redux-store/actions';
import { Values as ContributorValues } from '../components/investors/contributorDetails';
import { Values as DocumentValues } from '../components/investors/documentDetails';
import { Values as NonIndividualDocValues } from '../components/NonIndividualInvestor/DocumentDetails';
import {
  getSubQuestionsIndex,
  Values as NonIndividualFatcaValues,
} from '../components/NonIndividualInvestor/fatca';
import {
  addressTypesMasters,
  adminSupportMaster,
  applicantStatusMasters,
  APPLICATION_TYPE,
  BankAccountTypeForVenture,
  BankAccountTypeMaster,
  CpUboCodesMaster,
  genderMasters,
  grossAnnualMasters,
  investorTypeMasters,
  nationalitiesMasters,
  NomineeRelations,
  NRIBankAccountTypeMaster,
  occupationDetailsMasters,
  PEPsMasters,
  ReasonMaster,
  Relations,
  SourceOfFundsMaster,
  YES_NO_MASTER,
  YesNoMaster,
} from './constant';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { Values as NonIndividualContactValues } from '../components/NonIndividualInvestor/ContactDetails';
import { Values as individualFatcaValues } from '../components/investors/fatca';
import ConsentEmailApproveReject from '../components/ConsentEmailComponent';
import { checkOthers } from '../components/investors/additionalKYCDetails';
import { mdmsAccridiatedInfoType } from '../redux-store/types/mdms';

export const contributorDetailsSchema = (accridiatedInfo: mdmsAccridiatedInfoType) =>
  yup.object().shape({
    folio_no: yup
      .string()
      .nullable()
      .when('applicationSource', {
        is: (applicationSource: string) => folioValidationCheck(applicationSource),
        then: yup
          .string()
          .nullable()
          .matches(alphaNumeric, 'Special characters are not allowed')
          .required('Folio Number is required'),
      }),
    applicants: yup.array().of(
      yup.object().shape({
        name: yup.string().nullable().required('Name is required'),
        gender: yup
          .string()
          .nullable()
          .oneOf(genderMasters, 'Invalid value for Gender')
          .required('Please select gender'),
        motherName: yup
          .string()
          .nullable()
          .matches(stringRegex, 'Special characters and numbers not allowed'),
        fatherOrSpouseName: yup.string().nullable().required('Father/Spouse Name is required'),
        dateOfBirth: yup
          .string()
          .nullable()
          // .test(
          //   'dateOfBirth',
          //   'Age should be greater than 18',
          //   (dateOfBirth) => !isMinor(dateOfBirth || '')
          // )
          .required('Date of Birth is required'),
        // status: yup
        //   .string()
        //   .nullable()
        //   .required('Status is required'),
        nationality: yup
          .string()
          .nullable()
          .test('nationality', (value, context) => {
            const fieldValue = value === null ? '' : value;
            const { options, createError, path, parent } = context;
            const { context: optionsContext } = options as InternalOptions<ContributorValues>;
            const { nationalityDropdown = [] } = optionsContext || {};
            if (!fieldValue) {
              return createError({
                message: 'Country of Nationality is required',
                path,
              });
            }
            if (!nationalityDropdown.includes(fieldValue)) {
              return createError({
                message: 'Invalid value for Nationality',
                path,
              });
            }
            // if (
            //   applicantStatusMasters[parent.status] === applicantStatusMasters.NRI &&
            //   value?.toUpperCase() !== 'INDIAN'
            // ) {
            //   return createError({
            //     message: 'Invalid value for Nationality',
            //     path,
            //   });
            // }
            return true;
          }),
        jointApplicantRelation: yup
          .string()
          .nullable()
          .test('jointApplicationRelation', (value, context) => {
            const fieldValue = value === null ? '' : value;
            const { options, createError, path } = context;
            const { index } = options as InternalOptions<ContributorValues> & {
              index: number;
            };

            if (index !== 0) {
              if (!fieldValue) {
                return createError({
                  message: 'Relationship with first applicant is required',
                  path,
                });
              }
              if (fieldValue && !Relations.includes(fieldValue)) {
                return createError({
                  message: 'Invalid value for Relationship with first applicant',
                  path,
                });
              }
            }
            return true;
          }),
        relationShipWithFirstApplicant: yup
          .string()
          .nullable()
          .when('jointApplicantRelation', {
            is: (jointApplicantRelation: string | null) => jointApplicantRelation === 'Others',
            then: yup.string().nullable().required('Please Specify Relationship'),
          }),
        isAccreditedInvestor: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .test('isAccreditedInvestor', (value, context) => {
                const fieldValue = value === null ? '' : value;
                const { options, createError, path, parent } = context;
                if (!fieldValue) {
                  return createError({
                    message: 'Accredited Investor is required',
                    path,
                  });
                }
                if (fieldValue && !Object.keys(YesNoMaster).includes(fieldValue)) {
                  return createError({
                    message: 'Invalid value for Accredited Investor',
                    path,
                  });
                }
                return true;
              }),
          }),
        accreditedInvestorType: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .test('accreditedInvestorType', (value, context) => {
                const fieldValue = value === null ? '' : value;
                const { options, createError, path, parent } = context;
                if (accreditedInvestorTypeCapture(parent.isAccreditedInvestor || '')) {
                  if (!fieldValue) {
                    return createError({
                      message: 'Please select anyone',
                      path,
                    });
                  }
                  if (fieldValue && !Object.keys(accridiatedInfo).includes(fieldValue)) {
                    return createError({
                      message: 'Please select a valid value',
                      path,
                    });
                  }
                }
                return true;
              }),
          }),
      })
    ),
  });

const validatePermanentAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { permanentAddressSameAsCorresponding = false } =
    currentApplicant.address.correspondence || {};
  return permanentAddressSameAsCorresponding ? true : fieldValue !== '';
};

const validatePincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  if (!fieldValue) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode should be 6 digit code',
      path,
    });
  }
  if (parent.country?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }

  return true;
};

export const contactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      phoneNumberoffice: yup.string().nullable(),
      phoneNumberResidence: yup.string().nullable(),
      email: yup
        .string()
        .nullable()
        .matches(emailRegex, 'invalid Email ID')
        .required('Email is required'),
      contactPersonEmail: yup.string().nullable().matches(emailRegex, 'invalid Email ID'),
      contactPerson1Email: yup.string().nullable().matches(emailRegex, 'invalid Email ID'),
      mobile: yup
        .string()
        .nullable()
        .test('mobile', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === parent.countryNameAndCode) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return createError({
              message: 'Mobile is required',
              path,
            });
          }
          if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          if (!isValidPhoneNumber(value, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      countryNameAndCode: yup
        .string()
        .nullable()
        .test('countryNameAndCode', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === value) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return createError({
              message: 'Country Code is required',
              path,
            });
          }
          if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      primaryContactMobileNo: yup
        .string()
        .nullable()
        .test('primaryContactMobileNo', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === parent.primaryContactCountryNameAndCode) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return true;
          }
          if (
            parent.primaryContactCountryNameAndCode === 'India: +91' &&
            !phoneRegExp.test(value)
          ) {
            return createError({
              message: 'Invalid Primary Contact Person Mobile Number',
              path,
            });
          }
          if (!isValidPhoneNumber(value, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Primary Contact Person Mobile Number',
              path,
            });
          }

          return true;
        }),
      primaryContactCountryNameAndCode: yup
        .string()
        .nullable()
        .test('primaryContactCountryNameAndCode', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === value) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return true;
          }
          if (
            parent.primaryContactMobileNo &&
            !isValidPhoneNumber(parent.primaryContactMobileNo, codesList as CountryCode)
          ) {
            return createError({
              message: 'Invalid Primary Contact Person Mobile Number',
              path,
            });
          }

          return true;
        }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .required('Address line 1 is required'),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .required('Address line 2 is required'),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => validatePincodeField(value, context)),
          city: yup.string().nullable().required('City is required'),
          state: yup.string().nullable().required('State is required'),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [] } = optionsContext || {};

              if (!value) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (value && !countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validatePermanentAddressFields(value, context)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) =>
              validatePermanentAddressFields(value, context)
            ),
          pincode: yup
            .string()
            .trim()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                return validatePincodeField(value, context);
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) =>
              validatePermanentAddressFields(value, context)
            ),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) =>
              validatePermanentAddressFields(value, context)
            ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [] } = optionsContext || {};
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                // if (
                //   applicantStatusMasters[status || ''] === applicantStatusMasters.NRI &&
                //   value.toUpperCase() === 'INDIA'
                // ) {
                //   return createError({
                //     message:
                //       "If the Status is Individual (NRI) then Country can't be choosen as INDIA",
                //     path,
                //   });
                // }
                if (!countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // country: yup
          //   .string()
          //   .nullable()
          //   .test('country', 'Country is required', (value, context) =>
          //     validatePermanentAddressFields(value, context)
          //   ),
        }),
      }),
    })
  ),
});

export const KYCDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      occupationDetails: yup
        .string()
        .oneOf(occupationDetailsMasters, 'Invalid value for occupation details')
        .required('Occupation details is required'),
      politicallyExposedPersonStatus: yup
        .string()
        .oneOf(PEPsMasters, 'Invalid value for PEP Status')
        .required('PEP status is required'),
      sourceOfFunds: yup.array().nullable(),
      // .oneOf(Object.keys(SourceOfFundsMaster), 'Invalid value for Source of Funds')
      // .required('Source of Funds is required'),
      otherSourceOfFund: yup.string().when('sourceOfFunds', {
        is: (sourceOfFunds: string[] | null) => checkOthers(sourceOfFunds || []),
        then: yup.string().nullable().required('Please Specify Source of Funds'),
      }),
      fundsOriginateFrom: yup.array().nullable(),
      // .required('The funds are derived from or originated from is required'),
    })
  ),
});

const createSchemaForNonIndianResident = (validationMessage: string) => {
  return {
    is: (
      taxResidentOfAnyCountryOtherThanIndia: string,
      wishToUploadFatcaDocument: boolean | null
    ) => taxResidentOfAnyCountryOtherThanIndia.toString() === 'Yes' && !wishToUploadFatcaDocument,
    then: yup.string().nullable().required(validationMessage),
  };
};

export const FATCAValidationSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      typeOfAddressProvidedAtKRA: yup
        .string()
        .nullable()
        .when('wishToUploadFatcaDocument', {
          is: (wishToUploadFatcaDocument: boolean | null) => !wishToUploadFatcaDocument,
          then: yup
            .string()
            .nullable()
            .oneOf(addressTypesMasters, 'Invalid value for Type of Address Provided At KRA')
            .required('Please select type of address provided'),
        }),
      placeOfBirth: yup
        .string()
        // .required('Please enter place of birth')
        .matches(stringRegex, 'Special characters and numbers not allowed'),
      // countryOfBirth: yup
      //   .string()
      //   .required('Please enter country of birth')
      //   .matches(stringRegex, 'Please enter country of birth'),
      countryOfBirth: yup
        .string()
        .nullable()
        .test('countryOfBirth', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
          const { countryDropdown = [] } = optionsContext || {};
          if (!parent.wishToUploadFatcaDocument) {
            if (!fieldValue) {
              return createError({
                message: 'country of birth is required',
                path,
              });
            }
            if (!countryDropdown.includes(fieldValue)) {
              return createError({
                message: 'Invalid value for country of birth',
                path,
              });
            }
          }
          return true;
        }),
      // countryOfNationality: yup
      //   .string()
      //   .required('Please enter country of nationality')
      //   .matches(stringRegex, 'Please enter country of nationality'),
      countryOfNationality: yup
        .string()
        .nullable()
        .test('countryOfNationality', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
          const { countryDropdown = [] } = optionsContext || {};
          if (!parent.wishToUploadFatcaDocument) {
            if (!fieldValue) {
              return createError({
                message: 'country of nationality is required',
                path,
              });
            }
            if (!countryDropdown.includes(fieldValue)) {
              return createError({
                message: 'Invalid value for country of nationality',
                path,
              });
            }
          }
          return true;
        }),
      taxIdResidentOfAnyCountryOtherThanIndia: yup
        .string()
        .nullable()
        .when('wishToUploadFatcaDocument', {
          is: (wishToUploadFatcaDocument: boolean) => !wishToUploadFatcaDocument,
          then: yup.string().nullable().required('Tax Resident of any country is required'),
        }),
      taxCountryName: yup
        .string()
        .nullable()
        .when(
          ['taxIdResidentOfAnyCountryOtherThanIndia', 'wishToUploadFatcaDocument'],
          createSchemaForNonIndianResident(
            'Country of Tax Residency/TIN Issuing Country is required'
          )
        ),
      taxID: yup
        .string()
        // .max(20, 'Invalid Tax ID')
        .when(
          ['taxIdResidentOfAnyCountryOtherThanIndia', 'wishToUploadFatcaDocument'],
          createSchemaForNonIndianResident(
            'Tax Identification Number (TIN)/Equivalent Number is required'
          )
        ),
      idType: yup
        .string()
        .when(
          ['taxIdResidentOfAnyCountryOtherThanIndia', 'wishToUploadFatcaDocument'],
          createSchemaForNonIndianResident('ID Type is required')
        ),
      idTypeOthers: yup
        .string()
        .when(['taxIdResidentOfAnyCountryOtherThanIndia', 'idType', 'wishToUploadFatcaDocument'], {
          is: (
            taxIdResidentOfAnyCountryOtherThanIndia: string | null,
            idType: string | null,
            wishToUploadFatcaDocument: boolean
          ) =>
            taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' &&
            idType === 'OTHERS' &&
            !wishToUploadFatcaDocument,
          then: yup.string().nullable().required('Please Specify ID Type'),
        }),
      tinNotAvailableReason: yup
        .string()
        .when(['taxIdResidentOfAnyCountryOtherThanIndia', 'idType', 'wishToUploadFatcaDocument'], {
          is: (
            taxIdResidentOfAnyCountryOtherThanIndia: string | null,
            idType: string | null,
            wishToUploadFatcaDocument: boolean
          ) =>
            taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' &&
            idType === 'OTHERS' &&
            !wishToUploadFatcaDocument,
          then: yup.string().nullable().required('Reason (If TIN is not available) is required'),
        }),
      othersTinNotAvailableReason: yup
        .string()
        .when(
          [
            'taxIdResidentOfAnyCountryOtherThanIndia',
            'idType',
            'tinNotAvailableReason',
            'wishToUploadFatcaDocument',
          ],
          {
            is: (
              taxIdResidentOfAnyCountryOtherThanIndia: string | null,
              idType: string | null,
              tinNotAvailableReason: string | null,
              wishToUploadFatcaDocument: boolean
            ) =>
              taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' &&
              idType === 'OTHERS' &&
              !wishToUploadFatcaDocument &&
              ReasonMaster[tinNotAvailableReason || '']?.code === ReasonMaster.reason_c.code,
            then: yup.string().nullable().required('Please Specify Reason'),
          }
        ),
      usPerson: yup
        .string()
        .nullable()
        .when('wishToUploadFatcaDocument', {
          is: (wishToUploadFatcaDocument: boolean) => !wishToUploadFatcaDocument,
          then: yup.string().nullable().required('Field is required'),
        }),

      // nameOfEntity: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter name of entity')
      //   ),
      // dateOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter date of incorporation')
      //   ),
      // cityOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter city of incorporation')
      //   ),
      // countryOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter country of incorporation')
      //   ),
      // entityExcemptionCode: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter entity exemption code')
      //   ),
    })
  ),
});

export const adminSupportValidation = yup.object().shape({
  supportType: yup.string().required('Support Type is required'),
  application_number: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        [
          adminSupportMaster.change_expired_status,
          adminSupportMaster.change_status_to_draft,
        ].includes(adminSupportMaster[supportType]),
      then: yup.string().required('Application Number is required'),
    }),
  status: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        adminSupportMaster[supportType] === adminSupportMaster.change_expired_status,
      then: yup.string().required('Status is required'),
    }),
  phone: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        ![
          adminSupportMaster.change_expired_status,
          adminSupportMaster.change_status_to_draft,
          adminSupportMaster.process_signed_applications,
        ].includes(adminSupportMaster[supportType]),
      then: yup.string().test('phone', (value, context) => {
        const { createError, path, parent } = context;
        const codesList = getCountryCodes()
          .map((codes) => {
            if (codes.label === parent.countryCode) {
              return codes.countryCode;
            }
          })
          .filter((ele) => ele)
          .toString();
        if (!value) {
          return createError({
            message: 'Mobile number is required',
            path,
          });
        }
        if (parent.countryCode === 'India: +91' && !phoneRegExp.test(value)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        if (!isValidPhoneNumber(value, codesList as CountryCode)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        return true;
      }),
    }),
  // .required('Mobile Number is required')
  // .min(8, 'Invalid mobile number')
  // .max(16, 'Invalid mobile number')
  // .matches(phoneRegExp, 'Invalid mobile number'),
  // countryCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }
  //     return true;
  //   }),
});

export const nomineeDetailsSchema = yup.object().shape({
  nominees: yup.array().of(
    yup.object().shape({
      nomineeName: yup
        .string()
        .nullable()
        .matches(stringRegex, 'Special characters and numbers not allowed')
        .required('Nominee Name is required'),
      nomineeRelationship: yup.string().when('Relationship', {
        is: (Relationship: string | null) => Relationship === 'OTHERS',
        then: yup
          .string()
          .matches(stringRegex, 'Special characters and numbers not allowed')
          .required('Please Specify Relationship'),
      }),
      Relationship: yup
        .string()
        .nullable()
        .oneOf(NomineeRelations, 'Invalid value for Nominee Relationship')
        .required('Please select relationship'),
      dateOfBirth: yup.string().nullable().required('Date of Birth is required'),
      nomineePercentage: yup
        .number()
        .required('Nominee % is required')
        .typeError('Nominee % should be a number')
        .test('nomineePercentage', 'Total Nominee % should be equal to 100%', (value, context) => {
          const {
            options: { context: optionsContext },
          } = context;
          const { nominees } = optionsContext || {};
          const total = nominees
            .map((nominee: NomineeType) => Number(nominee.nomineePercentage))
            .reduce((a: number, b: number) => a + b, 0);
          return nominees.length && total === 100;
        }),
      guardianName: yup
        .string()
        .nullable()
        .when('dateOfBirth', {
          is: (dateOfBirth: string | null) => isMinor(dateOfBirth || ''),
          then: yup.string().nullable().required('Guardian Name is required'),
        }),
      dateOfAttainingMajority: yup
        .string()
        .nullable()
        .when('dateOfBirth', {
          is: (dateOfBirth: string | null) => isMinor(dateOfBirth || ''),
          then: yup
            .string()
            .nullable()
            .test('dateOfAttainingMajority', (value, context) => {
              const fieldValue = value === undefined ? '' : value;
              const { path, createError, parent } = context;
              if (!fieldValue) {
                return createError({
                  message: 'Date of Attaining Majority is required',
                  path,
                });
              } else if (
                fieldValue &&
                parent.dateOfBirth &&
                (new Date(parent.dateOfBirth).getDate() !== new Date(fieldValue).getDate() ||
                  new Date(parent.dateOfBirth).getMonth() !== new Date(fieldValue).getMonth() ||
                  new Date(parent.dateOfBirth).getFullYear() + 18 !==
                    new Date(fieldValue).getFullYear())
              ) {
                return createError({
                  message: 'Invalid Date of Attaining Majority',
                  path,
                });
              }
              return true;
            }),
        }),
      guardianAddress: yup
        .string()
        .nullable()
        .when('dateOfBirth', {
          is: (dateOfBirth: string | null) => isMinor(dateOfBirth || ''),
          then: yup.string().nullable().required('Guardian Address is required'),
        }),
      guardianTaxIdNumber: yup
        .string()
        .nullable()
        .when('dateOfBirth', {
          is: (dateOfBirth: string | null) => isMinor(dateOfBirth || ''),
          then: yup
            .string()
            .nullable()
            .required('Guardian Country tax identification number is required'),
        }),
    })
  ),
});

export const bankDetailsSchema = (applicants: Partial<Applicant>[] | undefined) =>
  yup.object().shape({
    banks: yup.array().of(
      yup.object().shape({
        swiftCode: yup.string().nullable().required('SWIFT Code/IFSC is required'),
        bankAccountNumber: yup
          .string()
          .nullable()
          .required('Bank Account Number is required')
          .matches(alphaNumeric, 'Special characters are not allowed')
          .test('bankAccountNumber', 'Account number already used', (value, context) => {
            const {
              options: { context: optionsContext },
            } = context;
            const { banks } = optionsContext || {};
            return banks.filter((bank: Bank) => bank.bankAccountNumber === value).length === 1;
          }),
        bankAccountType: yup
          .string()
          .nullable()
          .oneOf(Object.keys(BankAccountTypeForVenture), 'Invalid value for Account Type')
          .required('Account Type is required'),
        // .test('bankAccountType', 'Bank Account Type is required', (value, context) => {
        //   const {
        //     options: { context: optionsContext },
        //     createError,
        //     path,
        //     parent,
        //   } = context;
        //   const fieldValue = value === null ? '' : value;
        //   const { applicationType, status, banks } = optionsContext || {};
        //   if (
        //     applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
        //     !['huf'].includes(applicants ? applicants[0]?.investorType || '' : '') &&
        //     !fieldValue
        //   ) {
        //     return true;
        //   }
        //   if (
        //     applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
        //     fieldValue &&
        //     ![BankAccountTypeMaster.Current, BankAccountTypeMaster.Savings].includes(fieldValue)
        //   ) {
        //     return false;
        //   } else if (
        //     fieldValue &&
        //     applicantStatusMasters[status] !== applicantStatusMasters.NRI &&
        //     !Object.values(BankAccountTypeMaster).includes(fieldValue)
        //   ) {
        //     return false;
        //   }
        //   if (
        //     fieldValue &&
        //     applicantStatusMasters[status] === applicantStatusMasters.NRI &&
        //     !Object.values(NRIBankAccountTypeMaster).includes(fieldValue)
        //   ) {
        //     return false;
        //   }
        //   if (
        //     fieldValue &&
        //     applicantStatusMasters[status] === applicantStatusMasters.NRI &&
        //     banks.filter(
        //       (bank: Bank) =>
        //         Object.values(NRIBankAccountTypeMaster).includes(bank.bankAccountType) &&
        //         Object.values(NRIBankAccountTypeMaster).includes(fieldValue) &&
        //         bank.bankAccountType != fieldValue
        //     ).length === 1
        //   ) {
        //     return createError({
        //       message: 'Both Bank Account Type should be same',
        //       path,
        //     });
        //   }

        //   return !!fieldValue;
        // }),
        bankName: yup.string().nullable().required('Bank Name is required'),
        // bankBranch: yup.string().nullable().required('Bank Branch is required'),
        bankAddress: yup.string().nullable().required('Bank Address is required'),
        defaultBankAccount: yup
          .bool()
          .test('defaultBankAccount', 'Please check the default bank account', (value, context) => {
            const {
              options: { context: optionsContext },
            } = context;
            const { banks } = optionsContext || {};
            const isDefaultBankSelected = banks.some((bank: Bank) => bank.defaultBankAccount);
            return isDefaultBankSelected;
          }),
      })
    ),
  });

export const investmentPaymentDetailsSchema = (
  minCommitmentAmount: number,
  maxCommitmentAmount: number,
  minContributionPercentage: number,
  managementFee: number,
  currency: string | null,
  operatingExpenses: number
) =>
  yup.object().shape({
    schemeId: yup.string().required('Fund Scheme is required'),
    planId: yup.string().required('Class of Units Subscribed is required'),
    commitmentAmount: yup
      .number()
      .nullable()
      .when('applicationSource', {
        is: (applicationSource: string) => !folioValidationCheck(applicationSource),
        then: yup
          .number()
          .nullable()
          .min(
            minCommitmentAmount,
            `Capital Commitment should be greater than ${currencyConversion(
              minCommitmentAmount,
              currency
            )}`
          )
          .max(
            maxCommitmentAmount,
            `Capital Commitment should be between ${
              currencyConversion(minCommitmentAmount, currency) || '1 Cr'
            } and ${currencyConversion(maxCommitmentAmount, currency) || '2 Cr'}`
          )
          .required('Capital Commitment is required'),
      }),
    // contributionChequeAmount: yup
    //   .number()
    //   .nullable()
    //   .when('applicationSource', {
    //     is: (applicationSource: string) => !folioValidationCheck(applicationSource),
    //     then: yup
    //       .number()
    //       .nullable()
    //       .required(`Contribution Cheque Amount is required`)
    //       .max(
    //         yup.ref('commitmentAmount'),
    //         'Contribution Amount should not be greater than capital commitment'
    //       )
    //       .test(
    //         'contributionChequeAmount',
    //         `${
    //           Number(minContributionPercentage) === 100
    //             ? `Contribution Amount should be equal to capital commitment`
    //             : `Contribution Amount should be greater than or equal to ${minContributionPercentage}% of capital commitment`
    //         }`,
    //         (value, context) => {
    //           const { parent } = context;
    //           const { commitmentAmount } = parent;
    //           return (
    //             (100 * Number(value)) / Number(commitmentAmount) >=
    //             Number(minContributionPercentage)
    //           );
    //         }
    //       ),
    //   }),
    commitmentAmountForMigration: yup
      .string()
      .nullable()
      .when('applicationSource', {
        is: (applicationSource: string) => folioValidationCheck(applicationSource),
        then: yup.string().nullable().required('Capital Commitment is required'),
      }),
    // contributionAmountForMigration: yup
    //   .string()
    //   .nullable()
    //   .when('applicationSource', {
    //     is: (applicationSource: string) => folioValidationCheck(applicationSource),
    //     then: yup.string().nullable().required('Contribution Cheque Amount is required'),
    //   }),
    managementFeesRate: yup
      .number()
      .nullable()
      .required('Management Fee is required')
      .test(
        'managementFeesRate',
        `Management Fee  should be ${
          Number(managementFee) === 0
            ? 'equal to 0'
            : `in between or equal to 0 and ${Number(managementFee)}`
        }% `,
        // `Management Fee  should be in between or equal to 0 and ${Number(
        //   Number(managementFee) === 0 ? 100 : managementFee
        // )}%`,
        (value, context) => {
          return value ? true : value === 0 ? true : false;
        }
      )
      .max(
        Number(Number(managementFee) === 0 ? 0 : managementFee),
        `Management Fee  should be ${
          Number(managementFee) === 0
            ? 'equal to 0'
            : `in between or equal to 0 and ${Number(managementFee)}`
        }% `
      ),
    operatingExpenses: yup
      .number()
      .nullable()
      .required('Operating Expense is required')
      .min(
        0,
        `Operating Expense should be ${
          Number(operatingExpenses) === 0
            ? 'equal to 0'
            : `in between or equal to 0 and ${Number(operatingExpenses)}`
        }% `
      )
      .max(
        Number(operatingExpenses) === 0 ? 0 : operatingExpenses,
        `Operating Expense should be ${
          Number(operatingExpenses) === 0
            ? 'equal to 0'
            : `in between or equal to 0 and ${Number(operatingExpenses)}`
        }% `
      ),
  });

export const documentDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      documents: yup.array().of(
        yup.object().shape({
          documentsList: yup.array().of(
            yup.object().shape({
              documentName: yup
                .string()
                .nullable()
                .test(
                  'documentName',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, NonIndividualDocValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<NonIndividualDocValues> & { index: number };
                    const { applicants = [], banks } =
                      optionsContext || ({} as NonIndividualDocValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const {
                      required,
                      documentName,
                      documentType,
                      options: optionsDropdown,
                    } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    const fieldValue = optionsDropdown && value && optionsDropdown.includes(value);
                    if (optionsDropdown) {
                      if (!fieldValue && required === 'true') {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
              documentId: yup
                .string()
                .nullable()
                .test(
                  'documentId',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, NonIndividualDocValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<NonIndividualDocValues> & { index: number };
                    const { applicants = [], banks } =
                      optionsContext || ({} as NonIndividualDocValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const { required, documentName, documentType } =
                      applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    if (required === 'false') {
                      return true;
                    }
                    if (!value) {
                      if (!value && required === 'true') {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
            })
          ),
        })
      ),
    })
  ),
  nominees: yup.array().of(
    yup.object().shape({
      nomineedocuments: yup.array().of(
        yup.object().shape({
          documentsList: yup.array().of(
            yup.object().shape({
              documentName: yup
                .string()
                .nullable()
                .test(
                  'documentName',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { nominees = [] } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const {
                      required,
                      documentName,
                      documentType,
                      options: optionsDropdown,
                    } = nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[indexes[2]];
                    const fieldValue = optionsDropdown && value && optionsDropdown.includes(value);
                    if (optionsDropdown) {
                      if (!fieldValue && required === 'true') {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
              documentId: yup
                .string()
                .nullable()
                .test(
                  'documentId',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { nominees = [], banks } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const { required, documentName, documentType } =
                      nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[indexes[2]];
                    if (required === 'false') {
                      return true;
                    }
                    if (!value && required === 'true') {
                      return createError({
                        message: `Please upload ${documentName}`,
                        path,
                      });
                    }
                    return true;
                  }
                ),
            })
          ),
        })
      ),
    })
  ),
  documentDeclarationCheck: yup
    .boolean()
    .nullable()
    .test('documentDeclarationCheck', (value, context) => {
      const fieldValue = value === null ? '' : value;
      const { createError, path } = context;
      if (!fieldValue) {
        return createError({
          message: 'Acknowledgement is required',
          path,
        });
      }
      return true;
    }),
  // applicants: yup.array().of(
  //   yup.object().shape({
  //     documents: yup.object().shape({
  //       // identityProof: yup.object().shape({
  //       //   documentName: yup.string().required('Please select identity proof'),
  //       //   documentId: yup.string().required('Please upload Identity Proof'),
  //       // }),
  //       // addressProof: yup.object().shape({
  //       //   documentName: yup.string().required('Please select Address Proof'),
  //       //   documentId: yup.string().required('Please upload Address Proof'),
  //       // }),
  //       bankAccountProof: yup.object().shape({
  //         documentName: yup
  //           .string()
  //           .test('documentName', 'Please select Bank Account Proof', (value, context) =>
  //             DocumentRequiredForPennyDrop(value, context)
  //           ),

  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload Bank Account Proof', (value, context) =>
  //             DocumentRequiredForPennyDrop(value, context)
  //           ),
  //       }),
  //       poaNotarized: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload POA Notarized Document', (value, context) =>
  //             POADocumentValidation(value, context, 'poaNotarized')
  //           ),
  //       }),
  //       investorsConsent: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', "Please upload Investor's Consent Document", (value, context) =>
  //             POADocumentValidation(value, context)
  //           ),
  //       }),
  //       fatca: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload FATCA Document', (value, context) =>
  //             POADocumentValidation(value, context)
  //           ),
  //       }),
  //     }),
  //   })
  // ),
});

export const distributorsValidationSchema = (
  selectedDistributor: SubDistributor[],
  distributors: Distributor[]
) =>
  yup.object().shape({
    //role: useSelector((store: RootStateType) => store.auth),
    distributorId: yup
      .string()
      .nullable()
      .when('loginType', {
        is: (loginType: string) => loginType === 'distributor' && !!distributors.length,
        then: yup.string().nullable().required('Please select a distributor code'),
      }),
    subdistributorId: yup
      .string()
      .nullable()
      .when(['loginType', 'distributorId'], {
        is: () => 'distributor' && !!selectedDistributor.length,
        then: yup.string().nullable().required("Please select a distributor's rm name - code"),
      }),
    //selectedDistributor.length
  });

export const userManagementRmSchema = yup.object().shape({
  name: yup.string().nullable().required('RM Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  // phone: yup
  //   .string()
  //   .nullable()
  //   .min(8, 'Invalid Phone number')
  //   .max(16, 'Invalid Phone number')
  //   .matches(phoneRegExp, 'Invalid Phone number')
  //   .required('Phone Number is required'),
  phone: yup
    .string()
    .nullable()
    .test('phone', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Phone Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Phone number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Phone number',
          path,
        });
      }

      return true;
    }),
  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Phone Number',
          path,
        });
      }

      return true;
    }),
});

export const DistributorSchema = yup.object().shape({
  name: yup.string().nullable().required('Distributor Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().required('Email is required'),
    }),
  // phone: yup
  //   .string()
  //   .nullable()
  //   .min(8, 'Invalid Contact number')
  //   .max(16, 'Invalid Contact number')
  //   .matches(phoneRegExp, 'Invalid Contact number')
  //   .when('type', {
  //     is: (value: string) => value === 'individual',
  //     then: yup.string().nullable().required('Contact Number is required'),
  //   }),
  phone: yup
    .string()
    .nullable()
    .test('phone', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value && parent.type === 'individual') {
        return createError({
          message: 'Contact Number is required',
          path,
        });
      }
      if (
        value &&
        parent.type === 'individual' &&
        parent.countryNameAndCode === 'India: +91' &&
        !phoneRegExp.test(value)
      ) {
        return createError({
          message: 'Invalid Contact number',
          path,
        });
      }
      if (
        value &&
        parent.type === 'individual' &&
        !isValidPhoneNumber(value, codesList as CountryCode)
      ) {
        return createError({
          message: 'Invalid Contact number',
          path,
        });
      }

      return true;
    }),
  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value && parent.type === 'individual') {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (
        value &&
        parent.type === 'individual' &&
        parent.phone &&
        !isValidPhoneNumber(parent.phone, codesList as CountryCode)
      ) {
        return createError({
          message: 'Invalid Contact number',
          path,
        });
      }

      return true;
    }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // arnCode: yup.string().nullable().matches(arnCodeRegex, 'Invalid ARN Code Ex:- ARN-12345'),
  // .required('ARN Code is required'),
  rmId: yup
    .string()
    .nullable()
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().nullable().required('RM Name is required'),
    }),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const TrusteeAndFundManagerSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  // phone: yup
  //   .string()
  //   .nullable()
  //   .required('Mobile Number is required')
  //   .min(8, 'Invalid Mobile number')
  //   .max(16, 'Invalid Mobile number')
  //   .matches(phoneRegExp, 'Invalid Mobile number'),
  phone: yup
    .string()
    .nullable()
    .test('phone', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile Number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile Number',
          path,
        });
      }

      return true;
    }),
  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  dateOfBirth: yup
    .string()
    .nullable()
    .test(
      'dateOfBirth',
      'Age should be greater than 18',
      (dateOfBirth) => !isMinor(dateOfBirth || '')
    ),
  leegalityName: yup.string().nullable().required('Leegality Name is required'),
  // .required('Date of Birth is required'),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const aifApproverSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  // phone: yup
  //   .string()
  //   .nullable()
  //   .required('Mobile Number is required')
  //   .min(8, 'Invalid Mobile Number')
  //   .max(16, 'Invalid Mobile Number')
  //   .matches(phoneRegExp, 'Invalid Mobile Number'),
  phone: yup
    .string()
    .nullable()
    .test('phone', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile Number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile Number',
          path,
        });
      }

      return true;
    }),
  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const addFundManagerSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  // phone: yup
  //   .string()
  //   .nullable()
  //   .required('Mobile Number is required')
  //   .min(8, 'Invalid mobile number')
  //   .max(16, 'Invalid mobile number')
  //   .matches(phoneRegExp, 'Invalid mobile number'),
  phone: yup
    .string()
    .nullable()
    .test('phone', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  leegalityName: yup.string().nullable().required('Leegality Name is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const AuthorisedSignatoryAndPOASchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  // phone: yup
  //   .string()
  //   .nullable()
  //   .min(8, 'Invalid Mobile number')
  //   .max(16, 'Invalid Mobile number')
  //   .matches(phoneRegExp, 'Invalid Mobile number')
  //   .required('Mobile Number is required'),
  phone: yup
    .string()
    .nullable()
    .test('phone', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // distributorId: yup.string().required('Please Enter Distridutor Id'),
  // amcId: yup.string().required('Please Enter AmcId'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const DistributorRmSchema = yup.object().shape({
  name: yup.string().nullable().required("Distributor's RM name is required"),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  // phone: yup
  //   .string()
  //   .nullable()
  //   .min(8, 'Invalid Contact number')
  //   .max(16, 'Invalid Contact number')
  //   .matches(phoneRegExp, 'Invalid Contact number')
  //   .required('Contact Number is required'),
  phone: yup
    .string()
    .nullable()
    .test('phone', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Contact Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Contact number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Contact number',
          path,
        });
      }

      return true;
    }),
  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Contact number',
          path,
        });
      }

      return true;
    }),
  //distributors_RM_Code: yup.string().required('Please Enter Distributor RM code'),
  rmId: yup.string().nullable().required("AMC's RM name is required"),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

// name,
// email,
// countryCode,
// phone,
// panNumber,
// distributors_RM_Code,
// rmId,
// distributorId,
// pincode,
// buildingNo,
// streetName,
// city,
// state,
// country,

// pan: yup
// .string()
// .nullable()
// .uppercase()
// .length(10, 'PAN must be exactly 10 characters')
// .matches(individualPanRegex, 'Only individual PANs are allowed')
// .test('pan', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesPanNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'PAN is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
//     return createError({
//       message:
//         'There is already same pan number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),

// mobile: yup
// .string()
// .nullable()
// .min(8, 'Invalid Mobile Number')
// .max(16, 'Invalid Mobile Number')
// .matches(phoneRegExp, 'Invalid Mobile Number')
// .test('mobile', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesMobileNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Mobile Number is required',
//       path,
//     });
//   }
//   if (
//     authorisedSignatoriesMobileNumbers &&
//     authorisedSignatoriesMobileNumbers.includes(value)
//   ) {
//     return createError({
//       message:
//         'There is already same mobile number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
// email: yup
// .string()
// .nullable()
// .matches(emailRegex, 'invalid Email ID')
// .test('email', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesEmailID = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Email ID is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
//     return createError({
//       message:
//         'There is already same Email ID for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
export const amcAdminSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  // phone: yup
  //   .string()
  //   .nullable()
  //   .required('Mobile Number is required')
  //   .min(8, 'Invalid Mobile Number')
  //   .max(16, 'Invalid Mobile Number')
  //   .matches(phoneRegExp, 'Invalid Mobile Number'),
  phone: yup
    .string()
    .nullable()
    .test('phone', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile Number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile Number',
          path,
        });
      }

      return true;
    }),
  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
});

export const NonIndividualContributorValidationSchema = (
  accridiatedInfo: mdmsAccridiatedInfoType
) =>
  yup.object().shape({
    folio_no: yup
      .string()
      .nullable()
      .when('applicationSource', {
        is: (applicationSource: string) => folioValidationCheck(applicationSource),
        then: yup
          .string()
          .nullable()
          .matches(alphaNumeric, 'Special characters are not allowed')
          .required('Folio Number is required'),
      }),
    applicants: yup.array().of(
      yup.object().shape({
        name: yup.string().nullable().required('Name Of Entity is required'),
        cityOfIncorporation: yup
          .string()
          .nullable()
          .required('Place of Registration/Incorporation is required'),
        dateOfBirth: yup
          .string()
          .nullable()
          .required('Date of Registration/Incorporation is required'),
        taxIdNumber: yup.string().nullable().required('Tax ID is required'),
        // panNo: yup
        //   .string()
        //   .nullable()
        //   .uppercase()
        //   .length(10, 'PAN must be exactly 10 characters')
        //   .matches(nonIndividualPanRegex, 'invalid PAN')
        //   .required('PAN is required'),
        investorType: yup
          .string()
          .nullable()
          .oneOf(Object.keys(investorTypeMasters), 'Invalid value for Investor Type')
          .required('Please Select Investor Type'),
        netWorth: yup
          .string()
          .nullable()
          .test('netWorth', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError } = context;
            if (fieldValue && !numberRegex.test(fieldValue)) {
              return createError({
                message: 'Invalid amount',
                path,
              });
            }
            return true;
          }),
        grossAnnualIncome: yup
          .string()
          .nullable()
          .test('grossAnnualIncome', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError } = context;
            if (fieldValue && !grossAnnualMasters.includes(fieldValue)) {
              return createError({
                message: 'Invalid value for Gross Annual Income in USD',
                path,
              });
            }
            return true;
          }),
        isAccreditedInvestor: yup
          .string()
          .nullable()
          .test('isAccreditedInvestor', (value, context) => {
            const fieldValue = value === null ? '' : value;
            const { options, createError, path, parent } = context;
            if (!fieldValue) {
              return createError({
                message: 'Accredited Investor is required',
                path,
              });
            }
            if (fieldValue && !Object.keys(YesNoMaster).includes(fieldValue)) {
              return createError({
                message: 'Invalid value for Accredited Investor',
                path,
              });
            }
            return true;
          }),

        accreditedInvestorType: yup
          .string()
          .nullable()
          .test('accreditedInvestorType', (value, context) => {
            const fieldValue = value === null ? '' : value;
            const { options, createError, path, parent } = context;
            if (accreditedInvestorTypeCapture(parent.isAccreditedInvestor || '')) {
              if (!fieldValue) {
                return createError({
                  message: 'Please select anyone',
                  path,
                });
              }
              if (fieldValue && !Object.keys(accridiatedInfo).includes(fieldValue)) {
                return createError({
                  message: 'Please select a valid value',
                  path,
                });
              }
            }
            return true;
          }),
      })
    ),
  });

const validateNonIndividualPincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  addressType: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } =
    options as InternalOptions<NonIndividualContactValues> & {
      index: number;
    };
  const { applicants = [] } = optionsContext || {};
  if (!fieldValue && ['correspondence', 'permanent'].includes(addressType)) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (fieldValue && parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode code should be 6 digit code',
      path,
    });
  }
  if (fieldValue && parent.country?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }
  return true;
};

export const nonIndividualContactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      contactperson: yup.object().shape({
        address1: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
          ),
        address2: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
          ),

        landLineNumber: yup
          .string()
          .nullable()
          .test('landLineNumber', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (!fieldValue && parent.stdCode) {
              return createError({
                message: 'Please enter Landline number',
                path,
              });
            } else if (parent.landLineNumber && !numberRegex.test(parent.landLineNumber)) {
              return createError({
                message: 'Please enter valid Landline Number',
                path,
              });
            }
            return true;
          }),
        stdCode: yup
          .string()
          .nullable()
          .test('stdCode', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (!fieldValue && parent.landLineNumber) {
              return createError({
                message: 'Please enter STD Code',
                path,
              });
            } else if (parent.stdCode && !numberRegex.test(parent.stdCode)) {
              return createError({
                message: 'Please enter valid STD Code',
                path,
              });
            }
            return true;
          }),

        // city: yup.string().nullable().required('City is required'),
        // state: yup.string().nullable().required('State is required'),
        country: yup
          .string()
          .nullable()
          .test('country', (value, context) => {
            const { options, createError, path } = context;
            const { context: optionsContext } =
              options as InternalOptions<NonIndividualContactValues>;
            const { countryDropdown = [] } = optionsContext || {};
            if (!value) {
              return true;
            }
            if (value && !countryDropdown.includes(value)) {
              return createError({
                message: 'Invalid value for Country',
                path,
              });
            }
            return true;
          }),
        pincode: yup
          .string()
          .nullable()
          .test('pincode', (value, context) =>
            validateNonIndividualPincodeField(value, context, 'contactPerson')
          ),
        name: yup.string().nullable().required('Name is required'),
        email: yup
          .string()
          .nullable()
          .matches(emailRegex, 'invalid Email ID')
          .required('Email is required'),
        mobile: yup
          .string()
          .nullable()
          .test('mobile', (value, context) => {
            const { createError, path, parent } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === parent.countryNameAndCode) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value) {
              return createError({
                message: 'Mobile is required',
                path,
              });
            }
            if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }
            if (!isValidPhoneNumber(value, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
        countryNameAndCode: yup
          .string()
          .nullable()
          .test('countryNameAndCode', (value, context) => {
            const { createError, path, parent } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === value) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value) {
              return createError({
                message: 'Country Code is required',
                path,
              });
            }
            if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
      }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .required('Address line 1 is required'),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .required('Address line 2 is required'),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) =>
              validateNonIndividualPincodeField(value, context, 'correspondence')
            ),
          city: yup.string().nullable().required('City is required'),
          state: yup.string().nullable().required('State is required'),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext } =
                options as InternalOptions<NonIndividualContactValues>;
              const { countryDropdown = [] } = optionsContext || {};
              if (!value) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (!countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validatePermanentAddressFields(value, context)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) =>
              validatePermanentAddressFields(value, context)
            ),

          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                return validateNonIndividualPincodeField(value, context, 'permanent');
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) =>
              validatePermanentAddressFields(value, context)
            ),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) =>
              validatePermanentAddressFields(value, context)
            ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } =
                options as InternalOptions<NonIndividualContactValues> & {
                  index: number;
                };
              const { applicants = [], countryDropdown = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                if (!countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // .test('country', 'Country is required', (value, context) =>
          //   validatePermanentAddressFields(value, context)
          // ),
        }),
      }),
    })
  ),
});

export const nonIndividualDocumentDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      documents: yup.array().of(
        yup.object().shape({
          documentsList: yup.array().of(
            yup.object().shape({
              documentName: yup
                .string()
                .nullable()
                .test(
                  'documentName',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, NonIndividualDocValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<NonIndividualDocValues> & { index: number };
                    const { applicants = [], banks } =
                      optionsContext || ({} as NonIndividualDocValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const {
                      required,
                      documentName,
                      documentType,
                      options: optionsDropdown,
                    } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    const fieldValue = optionsDropdown && value && optionsDropdown.includes(value);
                    if (optionsDropdown) {
                      if (
                        documentType === 'bankAccountProof' &&
                        !fieldValue &&
                        banks &&
                        banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          .length !== 0
                      ) {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                      if (!fieldValue && required === 'true') {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
              documentId: yup
                .string()
                .nullable()
                .test(
                  'documentId',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, NonIndividualDocValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<NonIndividualDocValues> & { index: number };
                    const { applicants = [], banks } =
                      optionsContext || ({} as NonIndividualDocValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const { required, documentName } =
                      applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    if (required === 'false') {
                      return true;
                    }
                    if (!value) {
                      if (
                        !value &&
                        banks &&
                        banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          .length !== 0
                      ) {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
            })
          ),
        })
      ),
    })
  ),
});

export const nonIndividualAuthorisedSignatories = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  taxIdNumber: yup
    .string()
    .nullable()
    .test('taxIdNumber', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesPanNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.taxIdNumber))
        .flat();
      if (!value) {
        return createError({
          message: 'Tax ID is required',
          path,
        });
      }
      if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
        return createError({
          message:
            'There is already same Tax ID for an Authorised Signatory associated with this application',
          path,
        });
      }
      return true;
    }),

  // pan: yup
  //   .string()
  //   .nullable()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed')
  //   .test('pan', (value, context) => {
  //     const { path, createError, options } = context;
  //     const { context: optionsContext } = options as InternalOptions<Groups[]>;
  //     const authorisedSignatoriesPanNumbers = optionsContext
  //       ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
  //       .flat();
  //     if (!value) {
  //       return createError({
  //         message: 'PAN is required',
  //         path,
  //       });
  //     }
  //     if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
  //       return createError({
  //         message:
  //           'There is already same pan for an Authorised Signatory associated with this application',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  mobile: yup
    .string()
    .nullable()
    .test('mobile', (value, context) => {
      const { path, createError, options, parent } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesMobileNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
        .flat();
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (
        authorisedSignatoriesMobileNumbers &&
        authorisedSignatoriesMobileNumbers.includes(value)
      ) {
        return createError({
          message:
            'There is already same mobile number for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),

  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'invalid Email ID')
    .test('email', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesEmailID = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
        .flat();
      if (!value) {
        return createError({
          message: 'Email ID is required',
          path,
        });
      }
      if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
        return createError({
          message:
            'There is already same Email ID for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  designation: yup.string().nullable().required('Designation is required'),
});

const fieldValidation = (
  value: string | undefined | null | number,
  context: yup.TestContext<Record<string, ubo>>,
  mes: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { path, createError, parent } = context;
  if (!fieldValue && !parent.isFetchData) {
    return createError({
      message: `${mes} is required`,
      path,
    });
  }
  return true;
};

export const nonIndividualUbo = yup.object().shape({
  // panNumber: yup
  //   .string()
  //   .nullable()
  //   .uppercase()
  //   .test('panNumber', (value, context) => {
  //     const { path, createError, options } = context;
  //     const { context: optionsContext } = options as InternalOptions<ubo[]>;

  //     const uboPanNumbers = optionsContext?.map((ubo) => ubo.panNumber);
  //     if (!value) {
  //       return createError({
  //         message: 'Taxpayer ID Number/Equivalent ID Number is required',
  //         path,
  //       });
  //     }
  //     if (uboPanNumbers && uboPanNumbers.includes(value)) {
  //       return createError({
  //         message:
  //           'There is already same Taxpayer ID Number/Equivalent ID Number for an Ultimate Benificiary Ownership (UBO) associated with this application',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),

  taxIdNumber: yup
    .string()
    .nullable()
    .test('taxIdNumber', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ubo[]>;

      const taxID = optionsContext?.map((ubo) => ubo.taxIdNumber);
      if (!value) {
        return createError({
          message: 'Taxpayer ID Number/Equivalent ID Number is required',
          path,
        });
      }
      if (taxID && taxID.includes(value)) {
        return createError({
          message:
            'There is already same Taxpayer ID Number/Equivalent ID Number for an Ultimate Beneficiary Ownership (UBO) associated with this application',
          path,
        });
      }

      return true;
    }),

  dob: yup
    .string()
    .nullable()
    .required()
    .test('dob', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, parent } = context;
      if (!fieldValue) {
        return createError({
          message: 'Date of birth is required',
          path,
        });
      }
      // if (
      //   parent.panNumber &&
      //   individualPanRegex.test(parent.panNumber) &&
      //   isMinor(fieldValue || '')
      // ) {
      //   return createError({
      //     message: 'Age should be greater than 18',
      //     path,
      //   });
      // }
      // if (
      //   parent.panNumber &&
      //   individualPanRegex.test(parent.panNumber) &&
      //   maxAge(fieldValue || '')
      // ) {
      //   return createError({
      //     message: 'Age should be less than 125',
      //     path,
      //   });
      // }
      return true;
    }),
  name: yup
    .string()
    .nullable()
    .test('name', (value, context) => {
      return fieldValidation(value, context, 'Name');
    }),
  identificationType: yup
    .string()
    .nullable()
    .test('identificationType', (value, context) => {
      return fieldValidation(value, context, 'Identification Type');
    }),
  percentageOfBeneficialInterest: yup
    .number()
    .nullable()
    .test('percentageOfBeneficialInterest', (value, context) => {
      const fieldValue = value;
      const { path, createError, parent } = context;
      // if (fieldValue === null || (fieldValue === undefined && !parent.isFetchData)) {
      //   return createError({
      //     message: `Percentage Of Beneficial Interest is required`,
      //     path,
      //   });
      // }
      // if (fieldValue === 0 && !parent.isFetchData) {
      //   return createError({
      //     message: `Percentage Of Beneficial Interest should be greater 0`,
      //     path,
      //   });
      // }
      if (fieldValue) {
        if (fieldValue < 0 && !parent.isFetchData) {
          return createError({
            message: `Percentage Of Beneficial Interest should be greater 0`,
            path,
          });
        }
        if (fieldValue > 100 && !parent.isFetchData) {
          return createError({
            message: `Percentage Of Beneficial Interest must not exceed 100%`,
            path,
          });
        }
      }
      return true;
    }),
  countryOfTaxResidency: yup
    .string()
    .nullable()
    .test('countryOfTaxResidency', (value, context) => {
      return fieldValidation(value, context, 'Country Of Tax Residency');
    }),
  cpUboCode: yup
    .string()
    .nullable()
    .oneOf(Object.keys(CpUboCodesMaster), 'Invalid value for CP/UBO Code')
    .test('cpUboCode', (value, context) => {
      return fieldValidation(value, context, 'Cp/UboCode');
    }),
  placeAndCountryOfBirth: yup
    .string()
    .nullable()
    .test('placeAndCountryOfBirth', (value, context) => {
      return fieldValidation(value, context, 'Place And Country Of Birth');
    }),
  occupation: yup
    .string()
    .nullable()
    .test('occupation', (value, context) => {
      const { path, createError, parent } = context;
      if (![...occupationDetailsMasters, ''].includes(value || '')) {
        return createError({
          message: 'Invalid value for Occupation',
          path,
        });
      }
      return true;
    }),
  gender: yup
    .string()
    .nullable()
    .test('gender', (value, context) => {
      return fieldValidation(value, context, 'Gender');
    }),
  nationality: yup
    .string()
    .nullable()
    // .oneOf(nationalitiesMasters, 'Invalid value for Nationality')
    .test('nationality', (value, context) => {
      return fieldValidation(value, context, 'Nationality');
    }),
  // fatherName: yup
  //   .string()
  //   .nullable()
  //   .test('fatherName', (value, context) => {
  //     return fieldValidation(value, context, 'FatherName');
  //   }),
  ckycNumber: yup
    .string()
    .nullable()
    .length(14, 'Invalid CKYC Number')
    .test('ckycNumber', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, options, parent } = context;
      const { context: optionsContext } = options as InternalOptions<ubo[]>;
      if (!fieldValue && !parent.isFetchData) {
        return true;
      } else if (!numberRegex.test(fieldValue as string) && !parent.isFetchData) {
        return createError({
          message: 'CKYC Number allows only digits',
          path,
        });
      }
      const uboCkycNumbers = optionsContext?.map((ubo) => Number(ubo.ckycNumber).toString());
      if (uboCkycNumbers && uboCkycNumbers.includes(fieldValue as string) && !parent.isFetchData) {
        return createError({
          message:
            'There is already same CKYC Number for an Ultimate Beneficiary Ownership (UBO) associated with this application',
          path,
        });
      }
      return true;
    }),
  address2: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
    )
    .test('address2', (value, context) => {
      return fieldValidation(value, context, 'Address Line 1');
    }),
  address3: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
    )
    .test('address3', (value, context) => {
      return fieldValidation(value, context, 'Address Line 2');
    }),
  city: yup
    .string()
    .nullable()
    .test('city', (value, context) => {
      return fieldValidation(value, context, 'City');
    }),
  state: yup
    .string()
    .nullable()
    .test('state', (value, context) => {
      return fieldValidation(value, context, 'State');
    }),
  country: yup
    .string()
    .nullable()
    .test('country', (value, context) => {
      return fieldValidation(value, context, 'Country');
    }),
  pincode: yup
    .string()
    .nullable()
    .test('pincode', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, parent } = context;
      if (!fieldValue && !parent.isFetchData) {
        return createError({
          message: 'Pincode is required',
          path,
        });
      } else if (
        parent.country?.toLowerCase() === 'india' &&
        !indianPin.test(fieldValue as string) &&
        !parent.isFetchData
      ) {
        return createError({
          message: 'Pincode length should be 6 digits',
          path,
        });
      } else if (
        parent.country?.toLowerCase() !== 'india' &&
        !alphaNumeric.test(fieldValue as string) &&
        !parent.isFetchData
      ) {
        return createError({
          message: 'Invalid Pincode',
          path,
        });
      }
      return true;
    }),
});

export const nonIndividualControllingPersons = yup.object().shape({
  name: yup.string().nullable().required('Name of controlling person is required'),
  correspondenceAddress: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "invalid address format [special characters are not allowed except -/',&()#:.]"
    )
    .required('Correspondence address is required'),
  countryOfResidense: yup
    .string()
    .nullable()
    .required('Country of residence for tax purposes is required'),
  issuingCountry: yup.string().nullable().required('Issuing country is required'),
  controllingPersonType: yup.string().nullable().required('Controlling Person Type is required'),
  indentificationType: yup.string().nullable().required('Indentification Type is required'),
  indentificationNumber: yup
    .string()
    .nullable()
    .test('indentificationNumber', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
      const indentificationNumberArr = optionsContext?.map(
        (person) => person.indentificationNumber
      );
      if (!value) {
        return createError({
          message: 'Indentification Number is required',
          path,
        });
      }
      if (value && !alphaNumeric.test(value)) {
        return createError({
          message: 'Special Characters are Not allowed',
          path,
        });
      }
      if (indentificationNumberArr && indentificationNumberArr.includes(value)) {
        return createError({
          message:
            'There is already same Indentification Number for a Controlling Persons associated with this application',
          path,
        });
      }

      return true;
    }),
  occupationType: yup.string().nullable().required('Occupation Type is required'),
  occupation: yup
    .string()
    .nullable()
    .oneOf(occupationDetailsMasters, 'Invalid value for Occupation')
    .required('Occupation is required'),
  dateOfBirth: yup
    .string()
    .nullable()
    .test('dateOfBirth', (value, context) => {
      const { path, createError } = context;
      if (!value) {
        return createError({
          message: 'Date of Birth is required',
          path,
        });
      }
      if (isMinor(value)) {
        return createError({
          message: 'Age should be greater than 18',
          path,
        });
      }
      if (maxAge(value)) {
        return createError({
          message: 'Age should be less than 125',
          path,
        });
      }
      return true;
    }),
  countryOfBirth: yup.string().nullable().required('Country Of Birth is required'),
  nationality: yup.string().nullable().required('Nationality is required'),
  tin: yup
    .string()
    .nullable()
    .test('tin', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
      const controllingPersons = optionsContext?.map((person) => person.tin);
      if (!value) {
        return createError({
          message: 'TIN is required',
          path,
        });
      }
      if (value && !alphaNumeric.test(value)) {
        return createError({
          message: 'Special Characters are Not allowed',
          path,
        });
      }
      if (controllingPersons && controllingPersons.includes(value)) {
        return createError({
          message:
            'There is already same Tin for a Controlling Persons associated with this application',
          path,
        });
      }

      return true;
    }),
});

type errorObj = { fieldPath: string; fieldName: string };

const subQuestionsValidation = (
  fatca: nonIndividualQuestionsFatca,
  path: string
): errorObj | errorObj[] | undefined => {
  if (
    !fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.isMandatory === 'true' &&
    fatca.question_type !== 'Controlling_person_details'
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName:
        fatca.question_type === 'single_choice_radio'
          ? 'Field is required'
          : fatca.question_text
          ? fatca.question_text + ' is required'
          : (fatca.placeholder || 'Field') + ' is required',
    };
  }
  if (
    fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.validation === 'alphaNumeric' &&
    !alphaNumeric.test(fatca.answer)
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName: 'Special Characters are Not allowed',
    };
  }
  if (fatca.question_type === 'group' && fatca.isVisible && fatca.sub_questions?.length) {
    return fatca.sub_questions
      ?.map((qun) => subQuestionsValidation(qun, path) as errorObj)
      .filter((ele: errorObj) => ele)
      .flat();
  }
};

export const nonIndividualFatcaSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      fatcadetail: yup
        .array()
        .nullable()
        .of(
          yup.object().shape({
            answer: yup
              .string()
              .nullable()
              .when('wishToUploadFatcaDocument', {
                is: (wishToUploadFatcaDocument: boolean | null) => !wishToUploadFatcaDocument,
                then: yup
                  .string()
                  .nullable()
                  .test('answer', (value, context) => {
                    const { path, createError, parent } = context;
                    if (
                      !value &&
                      parent.isVisible &&
                      parent.isMandatory === 'true' &&
                      parent.backend_key &&
                      parent.question_type !== 'single_choice_radio'
                    ) {
                      return createError({
                        message:
                          parent.question_type === 'open_text_field'
                            ? (parent.placeholder || 'Field') + ' is required'
                            : parent.question_text
                            ? parent.question_text + ' is required'
                            : (parent.placeholder || 'Field') + ' is required',
                        path,
                      });
                    }
                    if (
                      value &&
                      parent.isVisible &&
                      parent.backend_key &&
                      parent.validation === 'alphaNumeric' &&
                      !alphaNumeric.test(value)
                    ) {
                      return createError({
                        message: 'Special Characters are Not allowed',
                        path,
                      });
                    }
                    if (
                      parent.question_type === 'group' &&
                      parent.isVisible &&
                      parent.sub_questions
                    ) {
                      //subQuestionsValidation(value, context)
                      const res: errorObj[] = [];
                      const val = parent.sub_questions
                        ?.map((qun: nonIndividualQuestionsFatca) => {
                          const r = subQuestionsValidation(qun, path?.split('.')[0]) as errorObj;
                          return r;
                          // return (r as errorObj)
                          //   ? new yup.ValidationError(r.fieldName, '', `${r.fieldPath}.answer`)
                          //   : (r as unknown as errorObj[])?.map(
                          //       (ele: errorObj) =>
                          //         new yup.ValidationError(ele.fieldName, '', `${ele.fieldPath}.answer`)
                          //     );
                        })
                        .filter((ele: errorObj) => ele)
                        .flat();
                      // .toString();
                      if (val.length) {
                        const error = val.map((ele: errorObj) => {
                          return new yup.ValidationError(
                            ele.fieldName,
                            '',
                            `${ele.fieldPath}.answer`
                          );
                        });
                        if (error.length) {
                          return new yup.ValidationError(error);
                        }

                        // return createError({
                        //   message: 'Field is Required1',
                        //   path: `${val}.answer`,
                        // });
                      }
                      return true;
                    }
                    return true;
                  }),
              }),
          })
        ),
    })
  ),
});

export const addPlanValidation = yup.object().shape({
  planCode: yup.string().nullable().required('Plan Code is required'),
  planDescription: yup.string().nullable().required('Plan Description is required'),
  schemeNature: yup.string().nullable().required('Scheme Nature is required'),
  // registrationNumber: yup.string().nullable().required('Registration Number is required'),
  fundManagerId: yup.string().nullable().required('Investment Manager Name is required'),
  trusteeId: yup.string().nullable().required('Trustee Name is required'),
  schemeCategory: yup.string().nullable().required('Scheme Category is required'),
  minCommitmentAmount: yup
    .number()
    .nullable()
    .moreThan(0, `Minimum Capital Commitment should be greater than 0`)
    .required('Minimum Capital Commitment is required'),
  maxCommitmentAmount: yup
    .number()
    .nullable()
    .min(
      yup.ref('minCommitmentAmount'),
      'Maximum Capital Commitment should be greater than or equal to Minimum Capital Commitment'
    )
    .required('Maximum Capital Commitment is required'),
  setupFee: yup
    .number()
    .required('Setup Fee is required')
    .min(0, 'Setup Fee should be in between or equal to 0 and 100')
    .max(100, 'Setup Fee should be in between or equal to 0 and 100'),
  managementFee: yup
    .number()
    .required('Management Fee is required')
    .min(0, 'Management Fee should be in between or equal to 0 and 100')
    .max(100, 'Management Fee should be in between or equal to 0 and 100'),
  operatingExpenses: yup
    .number()
    .required('Operating Expense is required')
    .min(0, 'Operating Expense should be in between or equal to 0 and 100')
    .max(100, 'Operating Expense should be in between or equal to 0 and 100'),
  exitLoadApplicable: yup
    .string()
    .nullable()
    .required('Exit Load Applicable is required')
    .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Exit Load Applicable'),
  exitLoadDetails: yup.array().when('exitLoadApplicable', {
    is: (exitLoadApplicable: string) => isFieldApplicable(exitLoadApplicable),
    then: yup.array().of(
      yup.object().shape({
        duration: yup.string().required('Period (after expiry of Lock-in Period) is required'),
        exitLoad: yup.string().required('Applicable Exit Charge is required'),
      })
    ),
  }),
  // hurdleRateOfReturns: yup.string().nullable().required('Transaction Fee % is required'),
  // performanceFee: yup.string().nullable().required('Performance Fee % is required'),
});

export const addFundValidation = yup.object().shape({
  schemeCode: yup.string().nullable().required('Scheme Code is required'),
  schemeName: yup.string().nullable().required('Scheme Name is required'),
  planCode: yup.string().nullable().required('Plan Code is required'),
  planDescription: yup.string().nullable().required('Plan Description is required'),
  schemeNature: yup.string().nullable().required('Scheme Nature is required'),
  // registrationNumber: yup.string().nullable().required('Registration Number is required'),
  fundManagerId: yup.string().nullable().required('Investment Manager Name is required'),
  trusteeId: yup.string().nullable().required('Trustee Name is required'),
  schemeCategory: yup.string().nullable().required('Scheme Category is required'),
  minCommitmentAmount: yup
    .number()
    .nullable()
    .moreThan(0, `Minimum Capital Commitment should be greater than 0`)
    .required('Minimum Capital Commitment is required'),
  maxCommitmentAmount: yup
    .number()
    .nullable()
    .min(
      yup.ref('minCommitmentAmount'),
      'Maximum Capital Commitment should be greater than or equal to Minimum Capital Commitment'
    )
    .required('Maximum Capital Commitment is required'),
  setupFee: yup
    .number()
    .required('Setup Fee is required')
    .min(0, 'Setup Fee should be in between or equal to 0 and 100')
    .max(100, 'Setup Fee should be in between or equal to 0 and 100'),
  managementFee: yup
    .number()
    .required('Management Fee is required')
    .min(0, 'Management Fee should be in between or equal to 0 and 100')
    .max(100, 'Management Fee should be in between or equal to 0 and 100'),
  operatingExpenses: yup
    .number()
    .required('Operating Expense is required')
    .min(0, 'Operating Expense should be in between or equal to 0 and 100')
    .max(100, 'Operating Expense should be in between or equal to 0 and 100'),
  exitLoadApplicable: yup
    .string()
    .nullable()
    .required('Exit Load Applicable is required')
    .oneOf(Object.keys(YES_NO_MASTER), 'Invalid value of Exit Load Applicable'),
  exitLoadDetails: yup.array().when('exitLoadApplicable', {
    is: (exitLoadApplicable: string) => isFieldApplicable(exitLoadApplicable),
    then: yup.array().of(
      yup.object().shape({
        duration: yup.string().required('Period (after expiry of Lock-in Period) is required'),
        exitLoad: yup.string().required('Applicable Exit Charge is required'),
      })
    ),
  }),
  // hurdleRateOfReturns: yup.string().nullable().required('Transaction Fee % is required'),
  // performanceFee: yup.string().nullable().required('Performance Fee %  is required'),
});

export const editFundValidation = yup.object().shape({
  schemeCode: yup.string().nullable().required('Scheme Code is required'),
  schemeName: yup.string().nullable().required('Scheme Name is required'),
});
