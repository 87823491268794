import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { SubHeading } from './investors/components';

const Officially_Valid_Document =
  'Document shall be deemed to be an Officially Valid Document even if there is a change in the name subsequent to its issuance, provided it is supported by a marriage certificate issued by the State Government or Gazette notification, indicating such a change of name.';

const OVD_Certificate =
  'Where certification of an OVD is done by the authorized officer of the Regulated Entity, such certified copy should be dated, signed and marked with ‘original sighted/verified’.';

const OVD_Contactable = 'The person certifying the OVD should be contactable.';

export const DocumentDeclaration =
  'I acknowledge that all of the measures mentioned above are taken.';

const styles = {
  content: {
    fontSize: 14,
    py: 1,
  },
  list: {
    fontSize: 14,
    py: 0.5,
  },
};

const Ol = ({
  children,
  style,
  type,
}: {
  children: JSX.Element;
  style?: any;
  type?: '1' | 'a' | 'i' | 'A' | 'I';
}) => {
  return (
    <Typography component={'ol'} style={style} type={type}>
      {children}
    </Typography>
  );
};
const JustifiedText = ({
  children,
  style,
  component = Typography,
}: {
  children: JSX.Element;
  style?: any;
  component?: React.ElementType;
}) => {
  return (
    <Typography
      sx={{
        textAlign: 'justify',
        //  ...styles.content
      }}
      style={style}
      component={component}>
      {children}
    </Typography>
  );
};
const Li = ({
  children,
  style,
}: {
  children: JSX.Element;
  style?: any;
  component?: React.ElementType;
}) => {
  return (
    <Typography
      sx={{
        textAlign: 'justify',
        //     ...styles.content,
        //     ...style,
      }}
      component={'li'}>
      {children}
    </Typography>
  );
};

const OriginalDocument = () => {
  return (
    <>
      <JustifiedText>
        <>
          In case a customer is unable to produce, or it might not be possible for customer to
          submit original documents for verification (e.g., in situations where Regulated Entity has
          no physical contact with the customer, or the onboarding of customer is done through
          non-face to face mode); a Regulated Entity should obtain a copy of the OVD that is
          certified to be a ‘true copy’ and such certification may be carried out by any one of the
          following:
        </>
      </JustifiedText>
      <Ol type="a">
        <>
          <Li>
            <>
              Authorised official of a bank located in a Financial Action Task Force (FATF)
              compliant jurisdiction with whom the individual has banking relationship;
            </>
          </Li>
          <Li>
            <>Notary Public (outside India);</>
          </Li>
          <Li>
            <>Court Magistrate (outside India);</>
          </Li>
          <Li>
            <>Judge (outside India);</>
          </Li>
          <Li>
            <>Certified public or professional accountant (outside India);</>
          </Li>
          <Li>
            <>Lawyer (outside India);</>
          </Li>
          <Li>
            <>
              The Embassy/Consulate General of the country of which the non-resident individual is a
              citizen; or
            </>
          </Li>
          <Li>
            <>any other authority as may be specified by the Authority.</>
          </Li>
        </>
      </Ol>
    </>
  );
};

const SubHeading2 = styled(SubHeading)(({ theme }) => ({
  backgroundColor: 'rgba(211, 47, 47, 0.1)',
  color: 'rgb(211, 47, 47)',
}));

export const IFSCGuidlines = () => {
  return (
    <Grid sx={{ width: '100%' }}>
      <SubHeading2>As per latest Guidelines of IFSC</SubHeading2>
      <Ol>
        <>
          <Li>
            <OriginalDocument />
          </Li>
          <Li>
            <Typography>{OVD_Contactable}</Typography>
          </Li>
          <Li>
            <Typography>{OVD_Certificate}</Typography>
          </Li>
          <Li>
            <Typography> {Officially_Valid_Document}</Typography>
          </Li>
        </>
      </Ol>
    </Grid>
  );
};
