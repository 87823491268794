import {
  Box,
  Grid,
  Typography,
  Theme,
  Hidden,
  IconButton,
  Button,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import { SubHeading } from '../investors/components';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory, useRouteMatch } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { accessType, ClassPlanProps } from '../../redux-store/types/funds';
import { fundAccess, getPlanDetail } from '../../redux-store/actions/funds';
import { Location } from 'history';
import { formatShortDate } from '../../utils/date';
import { KeyValue } from '../ApplicationDetails/contributor';
import { currencyConversion, isFieldApplicable } from '../../utils/utilityFunctions';
import { CurrencyMaster } from '../../utils/constant';
import ExitLoadDetails from '../ApplicationDetails/ExitLoadDetails';

export default function PlanDetails({
  location,
}: {
  location: Location<{ PlanId: string; fundId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [access, setAccess] = useState(false);
  const { PlanId, fundId } = location.state || {};
  const [planDetails, setPlanDetails] = useState<ClassPlanProps>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        if (PlanId) {
          const res = (await dispatch(getPlanDetail(PlanId))) as unknown as ClassPlanProps;
          setPlanDetails(res);
        }
        if (!isComponentActive) {
          return;
        }
        setAccess(fundSetupAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: { lg: 2, xs: 2 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: { lg: 'center', xs: 'flex-start' },
              flexDirection: { lg: 'row', xs: 'column' },
              justifyContent: 'space-between',
              ml: { lg: 7, xs: 2 },
              pt: { xs: 2, sm: 4 },
              mr: { lg: 7, xs: 2 },
              // pb: 1,
            }}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('view-plans', { fundId: fundId, PlanId: PlanId })}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  width: '100%',
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                    pr: 5,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('view-plans', { fundId: fundId })}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {'Plan Details'}
              </Typography>
              <Hidden smUp={true}>
                {access && (
                  <IconButton sx={{ p: 0, ml: 12 }}>
                    <CardMedia
                      component="img"
                      src="/images/edit-icon-outlined.svg"
                      alt="Edit Icon"
                      sx={{ width: 'unset' }}
                      onClick={() => history.push(`edit-plan`, { PlanId: PlanId, fundId: fundId })}
                    />
                  </IconButton>
                )}
              </Hidden>
            </Box>
            <Hidden only="xs">
              {access && (
                <IconButton sx={{ p: 0, m: 0 }}>
                  <CardMedia
                    component="img"
                    src="/images/edit-icon-outlined.svg"
                    alt="Edit Icon"
                    sx={{ width: 'unset' }}
                    onClick={() => history.push(`edit-plan`, { PlanId: PlanId, fundId: fundId })}
                  />
                </IconButton>
              )}
            </Hidden>
          </Box>

          {isLoading && <LinearProgress sx={{ ml: 5, mr: 5 }} />}
          {planDetails && (
            <>
              <Box sx={{ mr: { lg: 20, xs: 4 } }}>
                <SubHeading
                  sx={{
                    color: 'common.blue',
                    letterSpacing: 0.5 + 'px',
                    padding: { xs: '10px 10px', sm: '10px 30px' },
                    ml: { lg: 8, xs: 2 },
                  }}>
                  {'Plan Details'}
                </SubHeading>
              </Box>

              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Plan Code'} description={planDetails?.planCode} />
                      <KeyValue
                        title={'Plan Description'}
                        description={planDetails?.planDescription}
                      />
                      <KeyValue title={'Scheme Nature'} description={planDetails?.schemeNature} />
                      {/* <KeyValue
                        title={'Registration Number'}
                        description={planDetails?.registrationNumber}
                      /> */}
                      <KeyValue
                        title={'Scheme Category'}
                        description={planDetails?.schemeCategory}
                      />
                      <KeyValue title={'Country'} description={planDetails?.country} />
                      <KeyValue
                        title={'Currency'}
                        description={
                          planDetails?.currency
                            ? CurrencyMaster[planDetails?.currency]
                            : planDetails?.currency
                        }
                      />
                      <KeyValue
                        title={'Scheme Start Date'}
                        description={formatShortDate(planDetails?.schemeStartDate)}
                      />
                      <KeyValue
                        title={'Lock in Period(In years)'}
                        description={planDetails?.lockInPeriod}
                      />
                      <KeyValue
                        title={'Lock in Extension(In years)'}
                        description={planDetails?.lockInExtension}
                      />
                      <KeyValue title={'Face Value'} description={planDetails?.faceValue} />
                      <KeyValue title={'Fund Address 1'} description={planDetails?.fundAddress1} />
                      <KeyValue title={'Fund Address 2'} description={planDetails?.fundAddress2} />
                      <KeyValue title={'Fund Address 3'} description={planDetails?.fundAddress3} />
                      <KeyValue title={'Setup Fee'} description={planDetails?.setupFee} />
                      <KeyValue title={'Custodian'} description={planDetails?.custodian} />
                      <KeyValue
                        title={'Minimum Capital Commitment'}
                        description={planDetails?.minCommitmentAmount}
                        amount={currencyConversion(
                          planDetails?.minCommitmentAmount,
                          planDetails?.currency
                        )}
                      />
                      <KeyValue
                        title={'Maximum Capital Commitment'}
                        description={planDetails?.maxCommitmentAmount}
                        amount={currencyConversion(
                          planDetails?.maxCommitmentAmount,
                          planDetails?.currency
                        )}
                      />
                      <KeyValue
                        title={'Minimum Contribution Percentage'}
                        description={planDetails?.minContributionPercentage}
                      />
                      <KeyValue
                        title={'Investment Manager Name'}
                        description={planDetails?.fundManager.name}
                      />
                      <KeyValue title={'Trustee Name'} description={planDetails?.trustee.name} />
                      <KeyValue title={'Management Fee'} description={planDetails?.managementFee} />
                      <KeyValue
                        title={'Operating Expense'}
                        description={planDetails?.operatingExpenses}
                      />
                      <KeyValue
                        title={'Transaction Fee %'}
                        description={planDetails?.hurdleRateOfReturns}
                      />
                      <KeyValue
                        title={'Performance Fee %'}
                        description={planDetails?.performanceFee}
                      />
                      <KeyValue title={'T&C Link'} description={planDetails?.tcLink} />
                      <KeyValue
                        title={'Exit Load Applicable'}
                        description={planDetails?.exitLoadApplicable}
                      />
                      {isFieldApplicable(planDetails?.exitLoadApplicable) && (
                        <ExitLoadDetails exitLoadDetails={planDetails.exitLoadDetails} />
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
