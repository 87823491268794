import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles } from './index';
import { formatShortDate } from '../../utils/date';
import {
  currencyConversion,
  folioValidationCheck,
  isFieldApplicable,
} from '../../utils/utilityFunctions';
import ExitLoadDetails from './ExitLoadDetails';

export default function InvestAndPaymentDetails({
  application,
}: {
  application: Partial<ApplicationProps>;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Investment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue title={'Fund (Scheme)'} description={application.scheme?.schemeName} />
                <KeyValue
                  title={'Class of Units Subscribed'}
                  description={application.plan?.planDescription}
                />
                {application?.applicationSource &&
                !folioValidationCheck(application?.applicationSource) ? (
                  <KeyValue
                    title={'Capital Commitment'}
                    description={application.commitmentAmount}
                    amount={currencyConversion(
                      application.commitmentAmount,
                      application.plan?.currency
                    )}
                  />
                ) : (
                  <KeyValue
                    title={'Capital Commitment'}
                    description={application.commitmentAmountForMigration}
                  />
                )}
                <KeyValue
                  title={'Setup Fee'}
                  description={
                    application.setupFeePercentage || application.setupFeePercentage === 0
                      ? `${application.setupFeePercentage}% of the aggregate Capital Commitment`
                      : '-'
                  }
                />
                <KeyValue title={'Management Fee'} description={application.managementFeesRate} />
                <KeyValue title={'Operating Expense'} description={application.operatingExpenses} />
                {isFieldApplicable(application.exitLoadApplicable || '') && (
                  <ExitLoadDetails exitLoadDetails={application.exitLoadDetails || []} />
                )}
                {/* <KeyValue title={'Tax Rate'} description={application.taxRate} /> */}
                {/* <KeyValue title={'DrawDown Number'} description={application.drawDownNumber} /> */}
                {/* {application?.applicationSource &&
                !folioValidationCheck(application?.applicationSource) ? (
                  <KeyValue
                    title={'Contribution Amount'}
                    description={application.contributionChequeAmount}
                    amount={currencyConversion(
                      application.contributionChequeAmount,
                      application.plan?.currency
                    )}
                  />
                ) : (
                  <KeyValue
                    title={'Contribution Amount'}
                    description={application.contributionAmountForMigration}
                  />
                )} */}
                {/* <KeyValue
                  title={'Transaction Fee %'}
                  description={application.hurdleRateOfReturns}
                /> */}
                {/* <KeyValue title={'Performance Fee %'} description={application.performanceFee} /> */}
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          {/* <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Payment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue title={'Payment Bank Name'} description={application.paymentBankame} />
                <KeyValue
                  title={'Payment Bank Account Number'}
                  description={application.paymentBankAccountNumber}
                />
                <KeyValue
                  title={'Payment Bank Account Type'}
                  description={application.paymentBankAccontType}
                />
                <KeyValue title={'Mode Of Payment'} description={application.modeOfPayment} />
                <KeyValue
                  title={'Payment/Cheque Date'}
                  description={formatShortDate(application.chequeDate)}
                />
                <KeyValue title={'Payment Ref No'} description={application.paymentRefNo} />
              </Grid>
            </Gridstyles>
          </Grid> */}
        </Box>
      </Box>
    </>
  );
}
