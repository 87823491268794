import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React from 'react';
import { Gridstyles } from './index';
import { Applicant } from '../../redux-store/types/api-types';
import { ReasonMaster } from '../../utils/constant';

export default function FatcaDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              FATCA
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First/Solo Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>

                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {applicant.wishToUploadFatcaDocument && (
                        <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                          {/* <Checkbox defaultChecked disabled={true} /> */}
                          <Typography
                            sx={{
                              fontSize: 15,
                              fontWeight: 700,
                              color: 'rgba(0, 0, 0, 0.6)',
                              letterSpacing: '0.5px',
                            }}>
                            {`Wish to upload FATCA-CRS Declaration`}
                          </Typography>
                        </Grid>
                      )}

                      {!applicant.wishToUploadFatcaDocument && (
                        <>
                          <KeyValue
                            title={'Type of Address proof provided'}
                            description={applicant.typeOfAddressProvidedAtKRA}
                          />
                          <KeyValue
                            title={'Tax Resident Of Any Country'}
                            description={applicant.taxIdResidentOfAnyCountryOtherThanIndia}
                          />
                          <KeyValue title={'Place Of Birth'} description={applicant.placeOfBirth} />
                          <KeyValue
                            title={'Country Of Birth'}
                            description={applicant.countryOfBirth}
                          />
                          <KeyValue
                            title={'Country of Citizenship/Nationality'}
                            description={applicant.countryOfNationality}
                          />
                          {applicant.taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' && (
                            <>
                              <KeyValue
                                title={'Country of Tax Residency/TIN Issuing Country'}
                                description={applicant.taxCountryName}
                              />
                              <KeyValue
                                title={'ID Type'}
                                description={
                                  applicant.idType === 'OTHERS'
                                    ? applicant.idTypeOthers
                                    : applicant.idType
                                }
                              />
                              <KeyValue
                                title={'Tax Identification Number (TIN)/Equivalent Number'}
                                description={applicant.taxID}
                              />
                              {applicant.idType === 'OTHERS' && (
                                <>
                                  <KeyValue
                                    title={'Reason (If TIN is not available)'}
                                    description={
                                      applicant.tinNotAvailableReason
                                        ? ReasonMaster[applicant.tinNotAvailableReason].code ===
                                          ReasonMaster.reason_a.code
                                          ? ReasonMaster.reason_a.description
                                          : ReasonMaster[applicant.tinNotAvailableReason].code ===
                                            ReasonMaster.reason_b.code
                                          ? ReasonMaster.reason_b.description
                                          : ReasonMaster[applicant.tinNotAvailableReason].code ===
                                            ReasonMaster.reason_c.code
                                          ? applicant.othersTinNotAvailableReason
                                          : ''
                                        : ''
                                    }
                                  />
                                </>
                              )}
                            </>
                          )}
                          <KeyValue
                            title={'Are You a US Person'}
                            description={applicant.usPerson}
                          />
                          {applicant.fatcaDeclaration && (
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              {/* <Checkbox defaultChecked disabled={true} /> */}
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 700,
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  letterSpacing: '0.5px',
                                }}>
                                {`I/We acknowledge and confirm that the information provided above is true and correct to the best of my knowledge and belief. In case any of the above specified information is found to be false or untrue or misleading or misrepresenting,I/am aware that I may liable for it. I/We hereby authorize you [Fund / Investment Manager] to disclose, share, rely, remit in any form, mode or manner, all/ any of the information provided by me to you including information to any Indian or foreign judicial authorities/ agencies, Financial Intelligence Unit-India (FIU-IND), the tax/ revenue authorities in India or outside India wherever it is legally required and other investigation agencies without any obligation of advising me of the same. Further , I authorize to share the given information to other SEBI Registered Intermediaries/or any regulated intermediaries registered with SEBI / RBI / IRDA / PFRDA to facilitate single submission/ update & for other relevant purposes. I also undertake to keep you informed in writing about any changes/ modification to the above information in future within 30 days and also undertake to provide any other additional information as may be required at your/ Fund's end or by domestic or overseas regulators/ tax authorities. I/We authorize Fund /AMC/ RTA to provide relevant information to upstream payors to enable withholding to occur and pay out any sums from my account or close or suspend my account(s) without any obligation of advising me of the same. I also confirm that I have read and understood the FATCA & CRS Terms and Conditions given below and hereby accept the same.`}
                              </Typography>
                            </Grid>
                          )}

                          {/* <KeyValue title={'Name Of Entity'} description={applicant.nameOfEntity} />
                      <KeyValue
                        title={'Date Of Incorporation'}
                        description={applicant.dateOfIncorporation?.split(' ')[0]}
                      />
                      <KeyValue
                        title={'City Of Incorporation'}
                        description={applicant.cityOfIncorporation}
                      />
                      <KeyValue
                        title={'Entity Excemption Code'}
                        description={applicant.entityExcemptionCode}
                      /> */}
                        </>
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
