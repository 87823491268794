import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React from 'react';
import { Gridstyles } from './index';
import { Applicant } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { SourceOfFundsMaster } from '../../utils/constant';
import { checkOthers } from '../investors/additionalKYCDetails';

const displaySourceOfFund = (funds: string, otherFunds: string) => {
  return (funds as string)
    ?.split('*')
    ?.map((fund) => (fund === 'other' && otherFunds ? otherFunds : SourceOfFundsMaster[fund || '']))
    ?.toString()
    .replaceAll(',', ', ');
};

export default function KycDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Additional KYC Details
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={15}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Occupation Details'}
                        description={applicant.occupationDetails}
                      />
                      <KeyValue
                        title={'Gross Annual Income Details in USD'}
                        description={applicant.grossAnnualIncome}
                      />
                      {/* <KeyValue
                        title={'Net-Worth (Mandatory For Non-Individuals)'}
                        description={applicant.netWorth}
                      />
                      <KeyValue
                        title={'Net Worth Date'}
                        description={formatShortDate(applicant.netWorthDate)}
                      /> */}
                      <KeyValue
                        title={'Politically Exposed Person (PEP) Status'}
                        description={applicant.politicallyExposedPersonStatus}
                      />
                    </Grid>
                  </Gridstyles>
                  <SubHeading>Declaration on Origin(s) of Funds of {title}</SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={15}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {applicant.fundsDeclaration && (
                        <Grid item xs={12} display="flex" alignItems={'flex-start'} mt={1}>
                          {/* <Checkbox defaultChecked disabled={true} /> */}
                          <Typography
                            sx={{
                              fontSize: 15,
                              fontWeight: 700,
                              color: 'rgba(0, 0, 0, 0.6)',
                              letterSpacing: '0.5px',
                            }}>
                            {`I/We confirm that the funds transferred are sourced from my/our personal/business assets and/or from benefits of transactions due to me/us of which are known to me/us, more specifically, from (Please Select Source of Funds).`}
                          </Typography>
                        </Grid>
                      )}
                      <KeyValue
                        title={'Source of Funds'}
                        description={
                          applicant.sourceOfFunds
                            ? checkOthers(
                                ((applicant.sourceOfFunds as string)?.split('*') as string[]) || []
                              )
                              ? displaySourceOfFund(
                                  applicant.sourceOfFunds as string,
                                  applicant.otherSourceOfFund as string
                                )
                              : displaySourceOfFund(applicant.sourceOfFunds as string, '')
                            : applicant.sourceOfFunds
                        }
                      />

                      <KeyValue
                        title={'The funds are derived from or originated from'}
                        description={
                          applicant.fundsOriginateFrom
                            ? (applicant.fundsOriginateFrom as string)?.replaceAll('*', ', ')
                            : applicant.fundsOriginateFrom
                        }
                      />
                      {applicant.sourceWithinIndiaApproval && (
                        <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                          {/* <Checkbox defaultChecked disabled={true} /> */}
                          <Typography
                            sx={{
                              fontSize: 15,
                              fontWeight: 700,
                              color: 'rgba(0, 0, 0, 0.6)',
                              letterSpacing: '0.5px',
                            }}>
                            {`Derived from or originate from sources within India and written approval has been obtained from the Indian authorities for the investment, a copy of which is enclosed for the Company's records.`}
                          </Typography>
                        </Grid>
                      )}
                      {applicant.sourceWithinIndiaNoApproval && (
                        <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                          {/* <Checkbox defaultChecked disabled={true} /> */}
                          <Typography
                            sx={{
                              fontSize: 15,
                              fontWeight: 700,
                              color: 'rgba(0, 0, 0, 0.6)',
                              letterSpacing: '0.5px',
                            }}>
                            {`Derived from or originate from sources within India and no approval is required from the Indian authorities for such investment, as stated under a copy of the legal opinion enclosed for the Company's records.`}
                          </Typography>
                        </Grid>
                      )}
                      {applicant.sourceOfFundsDeclaration && (
                        <Grid item xs={12}>
                          {/* <Checkbox defaultChecked disabled={true} /> */}
                          <Typography
                            sx={{
                              fontSize: 15,
                              fontWeight: 700,
                              color: 'rgba(0, 0, 0, 0.6)',
                              letterSpacing: '0.5px',
                            }}>
                            {`It is hereby declared and confirmed that:`}
                          </Typography>
                          <Typography
                            sx={{ fontSize: 16, fontWeight: 400, pt: 1, display: 'flex' }}>
                            <Typography
                              sx={{ fontSize: 16, fontWeight: 500, pl: 2, pr: 1 }}
                              component="span">
                              •
                            </Typography>
                            I/we am/are not involved in any criminal, unlawful or money laundering
                            activity and the above- mentioned funds as well as funds to be used in
                            the future are clear, shall not be derived from or otherwise be
                            connected with any activity which is illegal or unlawful;
                          </Typography>
                          <Typography
                            sx={{ fontSize: 16, fontWeight: 400, pt: 1, display: 'flex' }}>
                            <Typography
                              sx={{ fontSize: 16, fontWeight: 500, pl: 2, pr: 1 }}
                              component="span">
                              •
                            </Typography>
                            I am not currently insolvent and any transfer of assets involving the
                            company will not render my estate or myself insolvent. I further declare
                            that I have never been convicted in any court of law for a criminal
                            offence or financial crime OR the Applicant has not been subject to any
                            bankruptcy, liquidation or similar proceedings and that no orders have
                            been issued against the Applicant by any legal or regulatory enforcement
                            bodies;
                          </Typography>
                          <Typography
                            sx={{ fontSize: 16, fontWeight: 400, pt: 1, display: 'flex' }}>
                            <Typography
                              sx={{ fontSize: 16, fontWeight: 500, pl: 2, pr: 1 }}
                              component="span">
                              •
                            </Typography>
                            The Fund or the Investment Manager may disclose this information to law
                            enforcement authorities when required by applicable laws and I/we shall
                            inform them of any change herein and provide any additional information
                            required by them;
                          </Typography>
                          <Typography
                            sx={{ fontSize: 16, fontWeight: 400, pt: 1, display: 'flex' }}>
                            <Typography
                              sx={{ fontSize: 16, fontWeight: 500, pl: 2, pr: 1 }}
                              component="span">
                              •
                            </Typography>
                            The information provided in this document is true, complete and correct.
                            I/We agree to provide any further information or documentary evidence in
                            respect of the funds upon request from the Fund.
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
