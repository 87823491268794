import { Box, Grid, Typography, Theme } from '@mui/material';
import { InfoDialog, SubHeading } from '../investors/components';
import React, { useEffect, useState } from 'react';
import { Item, ItemDetails, Gridstyles } from './index';
import { Applicant } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { SxProps } from '@mui/system';
import { YesNoMaster } from '../../utils/constant';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { accreditedInvestorTypeCapture } from '../../utils/utilityFunctions';
import { mdmsAccridiatedInfoType } from '../../redux-store/types/mdms';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAccridiatedInfo } from '../../utils/apis/contributor';

export const KeyValue = ({
  title,
  description,
  amount,
  sx = {},
}: {
  title: string | undefined;
  description?: string[] | string | number | boolean | null;
  amount?: number | null | undefined | string;
  sx?: SxProps<Theme>;
}): JSX.Element => {
  return (
    <Grid item xs={12} sm={6}>
      <Item sx={sx}>{title}</Item>
      <ItemDetails> {description || description === 0 ? description : '-'}</ItemDetails>
      {!!amount && amount !== 0 && <Item sx={{ pt: 0 }}> {amount}</Item>}
    </Grid>
  );
};

export default function ContributorDetails({
  applicants = [],
  modeOfHolding,
  applicationSource,
  accridiatedInfoMasters,
}: {
  applicants: Partial<Applicant>[];
  modeOfHolding: string;
  applicationSource: string | undefined;
  accridiatedInfoMasters: mdmsAccridiatedInfoType;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Contributor Details
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'text.secondary',
                }}>
                Mode of Holding:{' '}
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: 'rgba(0, 0, 0, 0.6)',
                    letterSpacing: '0.4px',
                    ml: 0.5,
                    textTransform: 'capitalize',
                  }}>
                  {modeOfHolding === 'either or survivor' ? 'Joint' : modeOfHolding || 'Single'}
                </Typography>
              </Typography>
              {/* {USER_ROLES.INVESTOR !== role && (
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: 'text.secondary',
                  }}>
                  Application Source:{' '}
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      color: 'rgba(0, 0, 0, 0.6)',
                      letterSpacing: '0.4px',
                      ml: 0.5,
                    }}>
                    {applicationSource ? ApplicationSourceMaster[applicationSource] : 'N/A'}
                  </Typography>
                </Typography>
              )} */}
            </Grid>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              const [open, setOpen] = useState(false);
              const handleClose = () => {
                setOpen(false);
              };
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading
                    sx={{
                      color: 'common.black',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    {title}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Name'} description={applicant.name} />
                      <KeyValue title={'Gender'} description={applicant.gender} />
                      <KeyValue
                        title={'Date Of Birth'}
                        description={formatShortDate(applicant.dateOfBirth)}
                      />
                      <KeyValue
                        title={'Father/Spouse Name'}
                        description={applicant.fatherOrSpouseName}
                      />
                      <KeyValue title={'Mother Name'} description={applicant.motherName} />
                      <KeyValue title={'Tax Id'} description={applicant.taxIdNumber} />
                      <KeyValue title={'Status'} description={applicant.status} />
                      <KeyValue
                        title={'Country of Nationality of Applicant'}
                        description={applicant.nationality}
                      />
                      {index !== 0 && (
                        <KeyValue
                          title={'RelationShip With First Applicant'}
                          description={applicant.relationShipWithFirstApplicant}
                        />
                      )}
                      <Grid item xs={12} sm={12}>
                        <Item>Accredited Investor</Item>
                        <ItemDetails>
                          {YesNoMaster[applicant.isAccreditedInvestor || ''] || '-'}
                        </ItemDetails>
                        {accreditedInvestorTypeCapture(applicant.isAccreditedInvestor || '') && (
                          <Item sx={{ pt: 0 }}>
                            {' '}
                            {accridiatedInfoMasters[applicant.accreditedInvestorType || '']
                              ?.label || ''}
                            {accridiatedInfoMasters[applicant.accreditedInvestorType || '']
                              ?.info && (
                              <Typography
                                component="span"
                                sx={{
                                  position: 'relative',
                                  left: 5,
                                  bottom: -5,
                                }}>
                                <InfoOutlinedIcon
                                  color="info"
                                  fontSize="small"
                                  onClick={() => setOpen(true)}
                                />
                              </Typography>
                            )}
                            <InfoDialog
                              onClose={handleClose}
                              open={open}
                              content={
                                accridiatedInfoMasters[applicant.accreditedInvestorType || '']
                                  ?.info || ''
                              }
                              title={
                                accridiatedInfoMasters[applicant.accreditedInvestorType || '']
                                  ?.label || ''
                              }
                              infoIcon={false}
                            />
                          </Item>
                        )}
                      </Grid>
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
