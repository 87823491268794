import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, FormikHelpers, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import { Applicant } from '../../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  grossAnnualMasters,
  occupationDetailsMasters,
  PEPsMasters,
  SourceOfFundsMaster,
  USER_ROLES,
} from '../../utils/constant';
import {
  applicationComparison,
  applyRoleBasedStatus,
  getApplicantName,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect, useState } from 'react';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { KYCDetailsSchema } from '../../utils/schema';
import { MFTextField } from '../../lib/formik';
import { useSnackbar } from 'notistack';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import MFCheckbox from '../../lib/formik/Checkbox';
import { showError } from '../../redux-store/actions/auth';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import { useNationalityList } from '../../utils/apis/contributor';

type Values = {
  applicants: Partial<Applicant>[];
  saveType: string;
};

const initialValues: Values = {
  applicants: [
    {
      occupationDetails: '',
      grossAnnualIncome: '',
      politicallyExposedPersonStatus: 'Not Applicable',
      sourceOfFunds: null,
      otherSourceOfFund: '',
      fundsOriginateFrom: null,
      sourceWithinIndiaApproval: false,
      sourceWithinIndiaNoApproval: false,
      sourceOfFundsDeclaration: true,
      fundsDeclaration: true,
    },
  ],
  saveType: 'save and proceed',
};

export const checkOthers = (sourceOfFunds?: string[]) => {
  return sourceOfFunds && sourceOfFunds?.map((_fund) => _fund?.toLowerCase())?.includes('other');
};

export const checkValueForIndia = (origin?: string[]) => {
  return origin && origin?.map((country) => country?.toLowerCase())?.includes('india');
};

const KYCDetails = ({
  applicant,
  index,
  values,
  nationalitiesMdmsResponse,
  setValues,
}: {
  applicant: string;
  index: number;
  values: Values;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  setValues: FormikHelpers<Values>['setValues'];
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <>
      <SubHeading>Additional KYC Details of {applicant} Applicant</SubHeading>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.occupationDetails`}
          label="Occupation Details *"
          items={occupationDetailsMasters.map((occupation) => ({
            key: occupation,
            value: occupation,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.grossAnnualIncome`}
          label="Gross Annual Income Details in USD"
          items={grossAnnualMasters.map((grossIncome) => ({
            key: grossIncome,
            value: grossIncome,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.politicallyExposedPersonStatus`}
          label="Politically Exposed Person (PEP) Status *"
          items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
        />
      </Grid>
      <SubHeading>Declaration on Origin(s) of Funds of {applicant} Applicant</SubHeading>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <MFCheckbox
          name={`applicants.${index}.fundsDeclaration`}
          label="I/We confirm that the funds transferred are sourced from my/our personal/business assets and/or from benefits of transactions due to me/us of which are known to me/us, more specifically, from (Please Select Source of Funds)."
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
            alignItems: 'flex-start',
            '.MuiTypography-root': {
              fontSize: 15,
              fontWeight: 500,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MultipleSelect
          name={`applicants.${index}.sourceOfFunds`}
          label="Source of Funds"
          items={Object.keys(SourceOfFundsMaster).map((fund) => ({
            key: fund,
            value: SourceOfFundsMaster[fund],
          }))}
          renderText="Selected Source of Funds"
        />
        {checkOthers((values.applicants[index].sourceOfFunds as string[]) || []) && (
          <MFTextField
            name={`applicants.${index}.otherSourceOfFund`}
            label="Please Specify Source of Funds *"
            placeholder={`Enter Source of Funds`}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <MultipleSelect
          name={`applicants.${index}.fundsOriginateFrom`}
          label="The funds are derived from or originated from"
          items={nationalitiesMdmsResponse.map((nationality) => ({
            key: nationality.name,
            value: nationality.name,
          }))}
          onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
            const items = nationalitiesMdmsResponse.map((nationality) => ({
              key: nationality.name,
              value: nationality.name,
            }));
            const getFieldValue =
              (value as string[] | number[])[(value as string[] | number[])?.length - 1] === 'all'
                ? (values.applicants[index].fundsOriginateFrom as string[] | number[])?.length ===
                  items.map((item) => item.key).length
                  ? []
                  : items.map((item) => item.key)
                : value;

            setValues({
              ...values,
              applicants: values.applicants.map((detail: any, idx: number) => {
                if (index === idx) {
                  return {
                    ...detail,
                    fundsOriginateFrom:
                      getFieldValue && (getFieldValue as string[])?.length > 0
                        ? getFieldValue
                        : null,
                    sourceWithinIndiaApproval: checkValueForIndia((getFieldValue as string[]) || [])
                      ? true
                      : detail.sourceWithinIndiaApproval,
                    sourceWithinIndiaNoApproval: checkValueForIndia(
                      (getFieldValue as string[]) || []
                    )
                      ? true
                      : detail.sourceWithinIndiaNoApproval,
                  };
                }
                return detail;
              }),
            });
          }}
          renderText="Selected Countries"
        />
      </Grid>
      {checkValueForIndia((values.applicants[index].fundsOriginateFrom as string[]) || []) && (
        <>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <MFCheckbox
              name={`applicants.${index}.sourceWithinIndiaApproval`}
              label="Derived from or originate from sources within India and written approval has been obtained from the Indian authorities for the investment, a copy of which is enclosed for the Company's records."
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
                alignItems: 'flex-start',
                '.MuiTypography-root': {
                  fontSize: 15,
                  fontWeight: 500,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <MFCheckbox
              name={`applicants.${index}.sourceWithinIndiaNoApproval`}
              label="Derived from or originate from sources within India and no approval is required from the Indian authorities for such investment, as stated under a copy of the legal opinion enclosed for the Company's records."
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
                alignItems: 'flex-start',
                '.MuiTypography-root': {
                  fontSize: 15,
                  fontWeight: 500,
                },
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sx={{ mt: 1 }}>
        <MFCheckbox
          name={`applicants.${index}.sourceOfFundsDeclaration`}
          label="It is hereby declared and confirmed that:"
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
            '.MuiTypography-root': {
              fontSize: 15,
              fontWeight: 500,
            },
          }}
        />
        <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1, display: 'flex' }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, pl: 2, pr: 1 }} component="span">
            •
          </Typography>
          I/we am/are not involved in any criminal, unlawful or money laundering activity and the
          above- mentioned funds as well as funds to be used in the future are clear, shall not be
          derived from or otherwise be connected with any activity which is illegal or unlawful;
        </Typography>
        <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1, display: 'flex' }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, pl: 2, pr: 1 }} component="span">
            •
          </Typography>
          I am not currently insolvent and any transfer of assets involving the company will not
          render my estate or myself insolvent. I further declare that I have never been convicted
          in any court of law for a criminal offence or financial crime OR the Applicant has not
          been subject to any bankruptcy, liquidation or similar proceedings and that no orders have
          been issued against the Applicant by any legal or regulatory enforcement bodies;
        </Typography>
        <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1, display: 'flex' }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, pl: 2, pr: 1 }} component="span">
            •
          </Typography>
          The Fund or the Investment Manager may disclose this information to law enforcement
          authorities when required by applicable laws and I/we shall inform them of any change
          herein and provide any additional information required by them;
        </Typography>
        <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1, display: 'flex' }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, pl: 2, pr: 1 }} component="span">
            •
          </Typography>
          The information provided in this document is true, complete and correct. I/We agree to
          provide any further information or documentary evidence in respect of the funds upon
          request from the Fund.
        </Typography>
      </Grid>
    </>
  );
};

export default function AdditionalKYCDetails(): JSX.Element {
  const [kycDetails, setkycDetails] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const nationalitiesMdmsMasters = useNationalityList() as unknown as nationaliyType;
  useEffect(() => {
    (async function () {
      try {
        if (nationalitiesMdmsMasters) setMdmsCountriesList(nationalitiesMdmsMasters.countries);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [nationalitiesMdmsMasters]);

  useEffect(() => {
    const { applicants: exisitingApplicants = [] } = application || {};
    setkycDetails({
      ...kycDetails,
      applicants: exisitingApplicants.map((applicant) => ({
        occupationDetails: applicant.occupationDetails || '',
        grossAnnualIncome: applicant.grossAnnualIncome || '',
        politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
          ? applicant.politicallyExposedPersonStatus
          : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
          ? ''
          : 'Not Applicable',
        sourceOfFunds: applicant.sourceOfFunds
          ? (applicant.sourceOfFunds as string)?.split('*')
          : null,
        otherSourceOfFund: applicant.otherSourceOfFund || '',
        fundsOriginateFrom: applicant.fundsOriginateFrom
          ? (applicant.fundsOriginateFrom as string)?.split('*')
          : null,
        sourceWithinIndiaApproval: applicant.sourceWithinIndiaApproval || false,
        sourceWithinIndiaNoApproval: applicant.sourceWithinIndiaNoApproval || false,
        sourceOfFundsDeclaration: applicant.sourceOfFundsDeclaration || true,
        fundsDeclaration: applicant.fundsDeclaration || true,
      })),
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const { applicants, saveType } = values;
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const updatedApplicants = exisitingApplicants.map((applicant, index) => {
        if (
          applicants[index].sourceOfFundsDeclaration === false ||
          (checkValueForIndia((applicants[index].fundsOriginateFrom as string[]) || []) &&
            applicants[index].sourceWithinIndiaApproval === false) ||
          (checkValueForIndia((applicants[index].fundsOriginateFrom as string[]) || []) &&
            applicants[index].sourceWithinIndiaNoApproval === false) ||
          applicants[index].fundsDeclaration === false
        ) {
          throw 'Declaration is required';
        }
        return {
          ...applicant,
          ...applicants[index],
          otherSourceOfFund: checkOthers((applicants[index].sourceOfFunds as string[]) || [])
            ? applicants[index].otherSourceOfFund
            : '',
          sourceWithinIndiaApproval: checkValueForIndia(
            (applicants[index].fundsOriginateFrom as string[]) || []
          )
            ? applicants[index].sourceWithinIndiaApproval
            : false,
          sourceWithinIndiaNoApproval: checkValueForIndia(
            (applicants[index].fundsOriginateFrom as string[]) || []
          )
            ? applicants[index].sourceWithinIndiaNoApproval
            : false,
          sourceOfFunds: applicants[index].sourceOfFunds
            ? applicants[index].sourceOfFunds?.toString().replace(/,/g, '*')
            : '',
          fundsOriginateFrom: applicants[index].fundsOriginateFrom
            ? applicants[index].fundsOriginateFrom?.toString().replace(/,/g, '*')
            : '',
        };
      });
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedApplicants,
        currentStep: !!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicants,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 4,
              //!!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={kycDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, KYCDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((_, index) => (
            <KYCDetails
              applicant={getApplicantName(index + 1)}
              key={index}
              index={index}
              values={values}
              nationalitiesMdmsResponse={mdmsCountriesList}
              setValues={setValues}
            />
          ))}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
